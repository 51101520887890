<template>
  <div class="contact-detail">
    <v-card-title primary-title>
      <v-layout>
        <v-flex xs4>
          <b class="pr-1">Vehicle:</b>
          <span class="headline">
            {{ vehicle.vehicleName }}
          </span>
        </v-flex>
        <v-flex xs5 offset-xs3>
          <span class="contact-row">
            <b>Year:</b>
            <p id="vehicle-detail-year">
              {{ vehicle.vehicleYear }}
            </p>
          </span>
          <span class="contact-row">
            &nbsp;&nbsp;
            <b>Make:</b>
            <p id="vehicle-detail-make">
              {{ vehicle.vehicleMake }}
            </p>
          </span>
          <span class="contact-row">
            &nbsp;&nbsp;
            <b>Model:</b>
            <p id="vehicle-detail-model">
              {{ vehicle.vehicleModel }}
            </p>
          </span>
          <span class="contact-row">
            &nbsp;&nbsp;
            <b>Passenger Count:</b>
            <p id="vehicle-detail-passenger-count">
              {{ vehicle.passengerCapacity }}
            </p>
          </span>
          <span class="contact-row">
            &nbsp;&nbsp;
            <b>VIN:</b>
            <p id="vehicle-detail-vin">
              {{ vehicle.vinNumber }}
            </p>
          </span>
        </v-flex>
      </v-layout>
    </v-card-title>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      vehicle: {},
    }
  },
  async mounted() {
    const response = await this.$store.dispatch(
      'vehicles/getVehicle',
      this.row.actionProps
    )
    this.vehicle = response.data?.vehicle
  },
}
</script>

<style lang="scss" scoped>
.contact-detail {
  padding: 20px;
  margin-top: 0;
  background-color: $blue-pale;

  .contact-row {
    b {
      padding-right: 15px;
    }

    a {
      text-decoration: none;
    }
  }
}
</style>
