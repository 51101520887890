<template>
  <v-card-title v-if="reservation" primary-title>
    <v-layout
      v-if="reservation.referredTo && validReferrals().length > 0"
      row
      wrap
    >
      <v-flex xs5>
        <v-btn-toggle v-model="referralSelected">
          <v-btn
            v-for="(referral, index) in validReferrals()"
            :id="`${id}-assign-detail-broker-select-referral-${index}`"
            :key="referral.reservationId"
            active-class="active text-start"
            class="referral-select btn-secondaryaction"
            :value="referral.managedId"
            @click="selectReferral(referral.reservationId)"
          >
            {{ referral.companyName }}
            <v-icon v-if="referralSelected === referral.reservationId" right>
              chevron_right
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-divider vertical />
      <v-layout
        v-if="
          activeReferralData.referralStatus !== 'accepted' &&
          activeReferralData.referralStatus !== 'confirmed'
        "
        align-center
      >
        <p
          :style="`margin-left: 10px; color: ${$cr.theme.error} !important; font-size: 16px;`"
        >
          The referral must be accepted before assigning drivers and vehicles
        </p>
      </v-layout>
      <v-layout v-else column>
        <v-layout row class="assign-header">
          <v-flex xs6>
            <a
              class="refresh-assignments-button"
              :class="{ debounce: disableRefreshAssignments }"
              @click="refreshAssignments"
            >
              Refresh Assignments
            </a>
            <v-layout
              row
              align-center
              justify-space-between
              style="margin: 0 19px"
            >
              <h4>Drivers</h4>
              <a
                style="margin-top: 4px; margin-right: 40px"
                @click="newDriverNav"
              >
                + Add New Driver
              </a>
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout
              row
              align-center
              justify-space-between
              style="margin: 0 19px"
            >
              <h4>Vehicles</h4>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          v-for="(tripVehicleGroup, tvgi) in model"
          :key="`trip-vehicle-group-${tvgi}`"
          column
        >
          <h6 v-if="tripVehicleGroup.name" class="margin-x-5">
            {{ tripVehicleGroup.name }}
          </h6>
          <v-layout
            v-for="(vehicleAssignment,
            vi) in tripVehicleGroup.vehicleAssignments"
            :key="`provider-assign-detail-outer-div-${vi}`"
            column
          >
            <v-layout row justify-space-around>
              <v-flex xs6>
                <v-layout column>
                  <v-layout
                    v-for="(driverAssignment,
                    di) in vehicleAssignment.driverAssignments"
                    :key="`driver-assignment-input-${vi}-${di}`"
                    row
                    class="provider-assignment-input"
                  >
                    <v-tooltip bottom left>
                      <template #activator="{ on }">
                        <CRTextField
                          :id="`assign-detail-broker-driver-${vi}-${di}`"
                          id-on-wrapper
                          label="Driver"
                          style="flex: 1"
                          :value="
                            driverAssignment.driver.name || 'Driver Unassigned'
                          "
                          :error="!driverAssignment.driver.name"
                          readonly
                          :background-color="$cr.theme.inputBackgroundGray"
                          append-icon="drivers"
                          append-icon-viewbox="0 0 32 32"
                          v-on="on"
                          @click.native="handleInputClick"
                        />
                      </template>
                      <span v-if="!!driverAssignment.driver.name">
                        {{ driverAssignment.driver.name }}
                        <br />
                        {{ getDriverPhoneNumber(driverAssignment.driver) }}
                      </span>
                      <span v-else>Driver Unassigned</span>
                    </v-tooltip>
                    <v-tooltip v-if="driverAssignment.userId" right>
                      <template #activator="{ on }">
                        <div
                          style="display: flex; align-items: center"
                          v-on="on"
                        >
                          <CRIcon
                            class="copy-icon"
                            :view-box="`0 0 18 18`"
                            :width="40"
                            color="primary"
                            @click.native="callCopy(driverAssignment.userId)"
                          >
                            driver_info
                          </CRIcon>
                        </div>
                      </template>
                      <v-layout>
                        <span>Copy Driver Info</span>
                      </v-layout>
                    </v-tooltip>
                    <div v-else style="min-width: 40px; min-height: 40px"></div>
                  </v-layout>
                </v-layout>
              </v-flex>
              <div class="provider-assignment-input">
                <CRTextField
                  :id="`assign-detail-broker-vehicle-${vi}`"
                  id-on-wrapper
                  :label="vehicleAssignment.vehicleTypeLabel || 'Vehicle'"
                  readonly
                  :background-color="$cr.theme.inputBackgroundGray"
                  :error="
                    !vehicleAssignment.vehicle.name ||
                    isUnassignedVehicleAssignment(vehicleAssignment)
                  "
                  :value="
                    vehicleAssignment.vehicle.name || 'Vehicle Unassigned'
                  "
                  append-icon="vehicles"
                  append-icon-viewbox="0 0 32 32"
                  @click.native="handleInputClick"
                />
              </div>
            </v-layout>
            <div
              v-if="
                model.length === 1 &&
                vi < tripVehicleGroup.vehicleAssignments.length - 1
              "
              class="provider-input-spacer"
            ></div>
          </v-layout>
          <div
            v-if="tvgi < model.length - 1"
            class="provider-input-spacer"
          ></div>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-layout v-else>
      <p class="text-xs-center">No referrals</p>
    </v-layout>
  </v-card-title>
</template>

<script>
import drivers from '@/services/drivers'
import {
  buildModel,
  isUnassignedVehicleAssignment,
} from '@/utils/tripAssignments'
import { copyDriverInfo } from '@/utils/driver'
import { EventBus } from '@/utils/event-bus'
import { getDriverPhoneNumber } from '@/utils/tripAssignments'
import {
  getTripAssignmentsForReservation,
  recalculateAssignmentPercentages,
} from '@/services/reservations'

export default {
  props: {
    reservation: {
      type: Object,
      default: () => undefined,
    },
    row: {
      type: Object,
      default: () => undefined,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      referralSelected: undefined,
      activeReferralData: {},
      driverList: [],
      vehicleList: [],
      referrals: [],
      vehicleAssignments: [],
      requiredDrivers: 0,
      requiredVehicles: 0,
      loadingDrivers: false,
      loadingVehicles: false,
      loadingReferral: false,
      selectModels: {
        drivers: [],
        vehicles: {},
      },
      model: [],
      disableRefreshAssignments: false,
      driverListDebounce: null,
      vehicleListDebounce: null,
    }
  },
  watch: {
    reservation() {
      this.selectReferral(this.validReferrals()[0].reservationId)
    },
  },
  async mounted() {
    this.reservation.referrals = this.reservation.referredTo
    const referralReservationIds = (this.reservation.referrals || []).map(
      (r) => r.reservationId
    )
    if (referralReservationIds.length) {
      const vehicleAssignmentsData = await getTripAssignmentsForReservation({
        reservationIds: referralReservationIds,
      })
      this.vehicleAssignments =
        vehicleAssignmentsData?.data?.vehicleAssignments || []
    }
    if (this.reservation?.referredTo?.[0]) {
      this.selectReferral(this.validReferrals()[0].reservationId)
    }
    EventBus.$on('reservations-refreshed', () => {
      this.$store.dispatch('app/closeDialog')
    })
    EventBus.$on('successful-add-driver', () => {
      if (this.driverListDebounce) {
        clearTimeout(this.driverListDebounce)
      }
      this.debounce = setTimeout(() => {
        this.getDriverList()
      }, 500)
    })
    EventBus.$on('successful-add-vehicle', () => {
      if (this.vehicleListDebounce) {
        clearTimeout(this.vehicleListDebounce)
      }
      this.debounce = setTimeout(() => {
        this.getVehicleList()
      }, 500)
    })
  },
  methods: {
    getDriverPhoneNumber,
    isUnassignedVehicleAssignment,
    async handleInputClick() {
      const component = () => import('./DispatchReservationSidebar.vue')

      const allDrivers = this.driverList.map((driver) => ({
        ...driver,
        name: `${driver.firstName} ${driver.lastName}`,
      }))
      const allVehicles = this.vehicleList.map((vehicle) => ({
        ...vehicle,
        name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
      }))

      const { vehicleAssignments, requiredVehicles } = this.selectModels
      let trip = {
        trip: {
          vehicles: requiredVehicles,
        },
      }
      const referral = this.activeReferralData
      referral.tripId = this.reservation.tripId

      const reservation = Object.assign(
        {},
        this.reservation,
        this.activeReferralData,
        { vehicleAssignments: vehicleAssignments },
        trip
      )

      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          title: 'Add Assignments',
          reservationId: referral.reservationId,
          managedId: referral.managedId,
          referral,
          isAssignmentsOnly: true,
          vehicles: allVehicles,
          drivers: allDrivers,
          garages: [],
          companyId: this.activeReferralData?.companyId,
        },
      })
    },
    async newDriverNav() {
      const component = () => import('../views/DriverSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          title: 'Add Driver',
          mode: 'referralAdd',
          companyId: this.activeReferralData?.companyId,
        },
      })
    },
    newVehicleNav() {
      const component = () => import('../views/VehiclesForm.vue')

      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          companyId: this.activeReferralData?.companyId,
          mode: 'add',
          title: 'Add Vehicle',
        },
      })
    },
    callCopy(driverId) {
      this.$store.dispatch(
        'app/showAlert',
        copyDriverInfo(driverId, this.driverList)
      )
    },
    driverContent(driverId) {
      if (!driverId) {
        return []
      }

      const user = this.driverList.find((person) => person.userId === driverId)

      if (!user) {
        return []
      }

      return [user.phoneNumber, user.email]
    },
    filterDrivers(item, queryText) {
      if (!queryText) {
        this.$emit('driver-cleared')
        return null
      }

      return (
        item.firstName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.lastName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    filterVehicles(item, queryText) {
      if (!queryText) {
        return null
      }

      return (
        item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      )
    },
    async getDriverList() {
      if (!this.activeReferralData?.companyId) {
        return
      }
      try {
        this.loadingDrivers = true
        const driverData = await drivers.getAllDriversForCompany({
          page: 1,
          pageSize: -1,
          companyId: this.activeReferralData?.companyId,
        })
        this.driverList = driverData?.data?.resultList
        this.loadingDrivers = false
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('app/showAlert', {
          message: `Unable to get drivers for company ${this.activeReferralData?.companyId}`,
        })
      }
    },
    async getVehicleList() {
      if (!this.activeReferralData?.companyId) {
        return
      }
      try {
        this.loadingVehicles = true
        const vehicleData = await this.$store.dispatch(
          'vehicles/getAllVehiclesForCompany',
          {
            page: 1,
            pageSize: -1,
            companyId: this.activeReferralData?.companyId,
          }
        )
        this.vehicleList = vehicleData?.data?.resultList
        this.loadingVehicles = false
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('app/showAlert', {
          message: `Unable to get vehicles for company ${this.activeReferralData?.companyId}`,
        })
      }
    },
    validReferrals() {
      if (!this.reservation || !this.reservation.referredTo) {
        return false
      }
      if (this.reservation?.referredTo) {
        return this.reservation.referredTo.filter(
          (item) => item?.referralStatus !== 'rejected'
        )
      }
      return false
    },
    selectReferral(referralId) {
      this.loadingReferral = true
      this.selectModels = {
        vehicleAssignments: [],
        requiredVehicles: [],
      }

      this.referralSelected = referralId

      // set active referral
      this.activeReferralData = this.reservation.referrals.find(
        (item) => item.reservationId === referralId
      )

      const vehicleAssignments = (this.vehicleAssignments || []).filter(
        (vehicleAssignment) => vehicleAssignment.reservationId === referralId
      )
      const requiredVehicles = this.activeReferralData?.requiredVehicles
      const requiredDrivers = this.activeReferralData?.requiredDrivers
      const tripVehicleGroups = this.activeReferralData?.tripVehicleGroups

      if (!vehicleAssignments || !requiredVehicles) return

      // get all driver/vehicles of active referral company
      if (this.vehicleListDebounce) {
        clearTimeout(this.vehicleListDebounce)
      }
      this.debounce = setTimeout(() => {
        this.getVehicleList()
      }, 500)
      if (this.driverListDebounce) {
        clearTimeout(this.driverListDebounce)
      }
      this.debounce = setTimeout(() => {
        this.getDriverList()
      }, 500)

      let model = buildModel(
        vehicleAssignments,
        requiredVehicles,
        requiredDrivers,
        tripVehicleGroups
      )
      this.model = model
      this.selectModels.vehicleAssignments = vehicleAssignments
      this.selectModels.requiredVehicles = requiredVehicles
      this.loadingReferral = false
    },
    async refreshAssignments() {
      this.disableRefreshAssignments = true
      const response = await recalculateAssignmentPercentages(
        this.reservation.reservationId
      )
      const updatedAssignmentPercentages =
        response.data?.reservationAssignmentPercentages
      EventBus.$emit(
        'update-assignment-percentages',
        updatedAssignmentPercentages
      )

      setTimeout(() => {
        this.disableRefreshAssignments = false
      }, 1500)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-item-group {
  flex-direction: column;
  width: 80%;
  box-shadow: none !important;
}

.copy-icon {
  margin-top: 15px !important;
  padding-left: 15px;
}

.referral-select {
  display: block;
  width: 100%;
  margin: 10px !important;
  border: 1px solid $primary !important;
  border-radius: 5px !important;

  &.active {
    color: $white !important;
    background-color: $primary !important;
  }

  .cr-icon {
    position: absolute;
    right: 0;
  }
}

.not-filled {
  ::v-deep input {
    &::placeholder {
      color: $error !important;
    }
  }
}

.provider-assignment-input {
  flex: 1;
  margin: 0 20px;

  ::v-deep .cr-text-field input {
    cursor: pointer !important;
  }
}

.provider-input-spacer {
  border-bottom: 1px solid $gray-mid-light;
  margin: 15px 0;
}

.assign-header {
  h4 {
    font-size: 20px;
  }
}

.saveBtn {
  margin-top: 25px;
}

.refresh-assignments-button {
  margin: 0 19px;
}

.debounce {
  pointer-events: none;
  color: $gray;
}
</style>
