import { ReservationDetailReferralDriver } from "@/models/dto";

/**
 * Copies driver information to the clipboard.
 *
 * This function searches for a driver by their `driverId` in the provided `driverList`.
 * If the driver is found, their name and, optionally, their phone number are copied to the clipboard.
 * If the driver is not found or the input is invalid, an error message is returned.
 *
 * @param driverId The unique identifier for the driver.
 * @param driverList An array of driver details.
 * @returns An object containing the type of the result ('error' or 'success') and a message.
 */
export const copyDriverInfo = (driverId: number, driverList: ReservationDetailReferralDriver[]): { type: string; message: string } => {
  const errorMsg = {
    type: 'error',
    message: 'Driver info could not be copied to your clipboard',
  }

  // Validate inputs
  if (!driverId || !driverList) {
    return errorMsg
  }

  // Find the user in the driver list
  const user = driverList.find((person) => person.userId === driverId)
  if (!user) {
    return errorMsg
  }

  // Prepare text to be copied
  let additionalInfo = ''
  if (user.phoneNumber) {
    additionalInfo = ', ' + user.phoneNumber
  }
  const copiedText = `${user.firstName} ${user.lastName}${additionalInfo}`

  // Copy to clipboard
  navigator.clipboard.writeText(copiedText).catch((e) => {
    console.error('Clipboard write failed', e)
    return errorMsg;
  })

  return {
    type: 'success',
    message: 'Driver info copied to your clipboard',
  };
}
