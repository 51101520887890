
import { Vue, Component, Prop } from 'vue-property-decorator';
import marketRates from '@/services/marketRates';
import { filter } from '@/utils/filter';
import { currencyFilter } from '@/utils/currency';
import { Vehicle, MarketRate } from '@/models/dto';

@Component
export default class ReservationQuickReferRateIcon extends Vue {
  @Prop({ type: Number, required: true }) companyId!: number;
  @Prop({ type: Object, required: true }) requiredVehicles!: Vehicle[];

  ratesString = '';

  async mounted() {
    const filters = filter()

    const companyIdFilter = {
      column: {
        _t_id: 'companyId',
        prop: 'company.companyId',
        filterType: 'eq',
      },
      value: this.companyId,
    }

    const marketplaceFilter = {
      column: {
        _t_id: 'marketplace',
        prop: 'marketplace',
        filterType: 'eq',
      },
      value: 1,
    }

    const activeFilter = {
      column: {
        _t_id: 'active',
        prop: 'active',
        filterType: 'eq',
      },
      value: 1,
    }

    filters.add(filters.createParent('and'), companyIdFilter)
    filters.add(filters.createParent('and'), marketplaceFilter)
    filters.add(filters.createParent('and'), activeFilter)

    const results: MarketRate[] = await marketRates
      .tableView({
        page: 1,
        pageSize: -1,
        showAllRates: true,
        filters: filters.asQueryParams(),
      })
      .then((d) => d.data.resultList)

    let marketRate: { [key: string]: { [key: string]: number } } = {};

    results
      .sort((a) => {
        if (a.vehicleId === null) return -1
        return 0
      })
      .filter((_marketRate) => {
        return this.requiredVehicles.some(
          (vehicle) => vehicle.vehicleType.label === _marketRate.vehicleType
        )
      })
      .forEach((_marketRate) => {
        let key = _marketRate.vehicleType

        if (!marketRate[key]) {
          marketRate[key] = {}
        }
        if (!marketRate[key][_marketRate.marketRateType]) {
          marketRate[key][_marketRate.marketRateType] = _marketRate.highRate
          if (_marketRate.marketRateType === 'Hourly Rate') {
            marketRate[key]['Hourly Minimum'] = _marketRate.hourlyMinimum!
          }
        }
      });

    let ratesList: string[] = [];
    for (let key in marketRate) {
      const vt = marketRate[key]
      const cf = currencyFilter

      ratesList.push(
        `${key}
          Daily: ${cf(vt['Daily Rate'])}
          Hourly: ${cf(vt['Hourly Rate'])}/${vt['Hourly Minimum']}h min
          Mileage: ${cf(vt['Dead Mile Rate'])} dead/${cf(
          vt['Mileage Rate']
        )} live
          Transfer Rate: ${cf(vt['Transfer Rate'])}
        `
      );
    }
    if (ratesList.length > 0) {
      this.ratesString = ratesList.join('\n')
    }

    if (this.requiredVehicles.every((v) => marketRate[v.vehicleType.label])) {
      this.$emit('can-calculate', true)
    }
  }
}
