
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { currencyFilter } from '@/utils/string';
import { EventBus } from '@/utils/event-bus';

interface CalculatedPrice {
  priceHigh: string;
  partnerTakeRate: string;
  userFacingPriceLog: string;
}

@Component
export default class OfferAmountField extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Number, default: null }) value!: number | null;
  @Prop({ type: Number, default: null }) quoteId!: number | null;
  @Prop({ type: Number, default: null }) companyId!: number | null;
  @Prop({ type: Number, default: null }) tripId!: number | null;
  @Prop({ type: Number, default: null }) offerAmount!: number | null;
  @Prop({ type: Boolean, default: false }) needsManualReferral: boolean | false;

  canCalculate = false;
  calculatedPrice: CalculatedPrice | null = null;
  hasCalculatedPrice = false;
  loading = false;
  errorMessages: string[] = [];

  //auto-calculate price for visible operators to get correctly calculated takeRate
  @Watch('canCalculate', {deep: true})
  onCanCalculateChange(canCalculate: boolean) {
    if (!canCalculate) {
      return
    }
    this.fillCalculatedOffer()
  }

  get offerAmountLabel(): string {
    return this.offerAmount
      ? `Offer Amount: ${currencyFilter(this.offerAmount)}`
      : '';
  }

  handleInputChange(value: string): void {
    this.$emit('input', value)
  }

  async fillCalculatedOffer(): Promise<void> {
    if (!this.hasCalculatedPrice) {
      await this.fetchCalculatedPrice()
    }

    if (this.calculatedPrice) {
      this.updatePriceFields()
    } else {
      this.errorMessages = ['No rates found']
    }
  }

  async fetchCalculatedPrice(): Promise<void> {
    try {
      this.loading = true
      await this.calculatePrice()
    } catch (e) {
      this.errorMessages = ['Error fetching rates']
    } finally {
      this.loading = false
    }
  }

  updatePriceFields(): void {
    const { priceHigh, partnerTakeRate } = this.calculatedPrice
    const partnerTakeRateValue = partnerTakeRate ? parseInt(partnerTakeRate) : 0
    const takeHomeAmount = this.calculateTakeHomeAmount(priceHigh, partnerTakeRateValue)

    this.updateInputField(takeHomeAmount)
    this.emitPricingChanges(takeHomeAmount, partnerTakeRateValue)
  }

  calculateTakeHomeAmount(priceHigh: string, partnerTakeRate: number): string {
    const priceHighValue = parseInt(priceHigh)
    return (priceHighValue * (1 - partnerTakeRate / 100)).toFixed(2)
  }

  updateInputField(takeHomeAmount: string): void {
    const inputField = this.$refs.input as any
    if (inputField) {
      inputField.internalValue = takeHomeAmount
    }
  }

  emitPricingChanges(takeHomeAmount: string, partnerTakeRate: number): void {
    this.$emit('input', takeHomeAmount)
    EventBus.$emit('pricing-change', partnerTakeRate, this.companyId, takeHomeAmount)
  }


  async calculatePrice(): Promise<void> {
    const offer = await this.$store.dispatch('quotes/getQuotePricingById', {
      quoteId: this.quoteId,
      companyId: this.companyId,
      needsManualReferral: this.needsManualReferral
    });
    this.calculatedPrice = offer.data.pricings.find(
      (p: any) =>
        p.tripId === this.tripId &&
        p.companyId === this.companyId &&
        p.autoSelected
    )

    this.hasCalculatedPrice = true;
  }
}
