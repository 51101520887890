<template>
  <v-form ref="commentForm" v-model="validNote" lazy-validation>
    <v-layout row wrap>
      <v-flex xs12>
        <slot name="headline">
          <span class="headline">Comments</span>
        </slot>
      </v-flex>
      <v-flex xs12>
        <br />
      </v-flex>
      <v-flex xs5>
        <div class="comments-list-container">
          <div class="comments-list">
            <div v-if="comments.length === 0" class="comment-container">
              <span class="comment-body">No comments.</span>
            </div>
            <div
              v-for="(comment, commentIndex) in comments"
              :key="commentIndex"
              class="comment-container"
            >
              <template v-if="comment.mode === 'edit'">
                <span class="comment-author">
                  {{ commentAuthorHandler(comment) }}
                  <CRIcon
                    v-if="editableComments"
                    id="comment-icon-edit-comment"
                    class="edit-icon"
                    color="primary"
                    @click.native="() => updateComment(comment)"
                  >
                    save
                  </CRIcon>
                </span>
                <v-textarea
                  id="comment-text-area-add-comment"
                  v-model="comment.note"
                  :outline="!!comment.error"
                  :error="!!comment.error"
                  :rules="isRequired('Comment must not be empty')"
                  class="comment-box"
                  rows="2"
                  flat
                  placeholder="Add a Comment"
                  solo
                  small
                />
              </template>
              <template v-else>
                <span class="comment-author">
                  {{ commentAuthorHandler(comment) }}
                  <CRIcon
                    v-if="editableComments"
                    id="comment-icon-edit-author"
                    class="edit-icon"
                    color="primary"
                    small
                    @click.native="() => onClickEdit(comment)"
                  >
                    edit
                  </CRIcon>
                </span>
                <br />
                <span class="comment-body">{{ comment.comment }}</span>
              </template>
            </div>
          </div>
        </div>
      </v-flex>
      <v-spacer />
      <v-flex text-xs-right xs6>
        <v-textarea
          ref="commentBody"
          v-model="note"
          :outline="validNote ? false : true"
          :rules="isRequired('Comment must not be empty')"
          class="comment-box"
          flat
          placeholder="Add a Comment"
          solo
        />
      </v-flex>
      <v-flex text-xs-right xs12>
        <v-btn class="btn-secondaryaction" @click="$emit('cancel')">
          Cancel
        </v-btn>
        <v-btn
          class="btn-primaryaction"
          :color="validNote ? '' : 'error'"
          @click="addComment"
        >
          Add
        </v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'

const commentAuthor = (comment) => {
  const { createdOn } = comment
  const commentDateTime = DateTime.fromISO(createdOn).toLocaleString(
    DateTime.DATETIME_SHORT
  )
  return `${commentDateTime} by ${comment?.userName} from ${comment?.companyName}`
}

export default {
  props: {
    comments: { type: Array, default: () => [] },
    commentAuthorHandler: { type: Function, default: commentAuthor },
    editableComments: { type: Boolean, default: false },
  },
  data() {
    return {
      note: '',
      validNote: true,
      formSubmitAttempt: false,
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    commentAuthor(comment) {
      const { createdOn } = comment
      const commentDateTime = DateTime.fromISO(createdOn).toLocaleString(
        DateTime.DATETIME_SHORT
      )
      return `${commentDateTime} by ${comment?.userName} from ${comment?.companyName}`
    },
    isRequired(message) {
      const isEmpty = (value) => {
        if (!this.formSubmitAttempt) {
          return true
        }
        if (typeof value === 'undefined') {
          return false
        }
        if (typeof value === 'string') {
          return value.length > 0
        }
        return false
      }
      return [(value) => isEmpty(value) || message]
    },
    onClickEdit(comment) {
      comment.mode = 'edit'
      this.$forceUpdate()
    },
    updateComment(comment) {
      const { note = '' } = comment
      if (note.length === 0) {
        this.showAlert({
          message: 'Comment may not be empty',
          type: 'error',
        })
        return
      }
      this.$emit('update', comment)
    },
    async addComment() {
      this.formSubmitAttempt = true
      const { value: note = '' } = this.$refs.commentBody
      this.validNote = this.$refs.commentForm.validate()
      if (!this.validNote) {
        this.validNote = false
        return
      }
      return this.$emit('save', note)
    },
  },
}
</script>

<style lang="scss" scoped>
.comments-list-container {
  max-height: 200px;
  overflow: scroll;
  border: 1px solid $gray-mid-light;
  border-radius: 5px;
}

.comments-list {
  padding: 4px;
  margin: 4px;
}

.comment-author {
  color: $gray;
}

.comment-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-btn {
  margin-right: 0 !important;
}
.edit-icon {
  float: right;
  cursor: pointer;
}
.update-button {
  margin-top: -20px;
}
</style>
