<template>
  <div style="max-width: 500px">
    <v-layout row>
      <v-flex xs12>
        <CRInput
          v-model="routeName"
          placeholder="Trip Name"
          label="Trip Name"
        />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-spacer />
      <div style="margin-right: -8px">
        <v-btn outline color="primary" @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    key: {
      type: Number,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
    tripId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.routeName = this.reservation.tripRouteName
    this.$forceUpdate()
  },
  methods: {
    async save() {
      //TODO: save the new trip name value
      const params = {
        tripId: this.tripId,
        payload: {
          routeName: this.routeName,
        },
      }
      await this.$store.dispatch('trips/editTripName', params)
      this.close()
    },
    close() {
      this.$emit('refresh-query-request')
    },
  },
}
</script>
