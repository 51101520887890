<template>
  <v-container grid-list-md>
    <v-layout>
      <v-flex>
        <v-layout row wrap>
          <v-flex>
            <h6>Checkout</h6>
            <div>{{ op(reservation, 'checkoutName') }}</div>
          </v-flex>
          <v-flex>
            <h6>Lead Source</h6>
            <div>{{ op(reservation, 'leadSource') }}</div>
          </v-flex>
          <v-flex>
            <h6>Booked On</h6>
            <div>
              {{
                DateTime.fromISO(op(reservation, 'createdOn')).toLocaleString(
                  DateTime.DATE_SHORT
                )
              }}
            </div>
          </v-flex>
          <v-flex>
            <h6>Marketplace</h6>
            <div>{{ op(reservation, 'isCharterUP') ? 'Yes' : 'No' }}</div>
          </v-flex>
          <v-flex
            v-if="
              reservation &&
              reservation.awardedTo &&
              reservation.awardedTo.length &&
              isOwner
            "
          >
            <h6>Awarded To</h6>
            <div
              v-for="(awardedTo, awardedToKey) in reservation.awardedTo"
              :key="`awardedTo.key.${awardedToKey}`"
            >
              {{ op(awardedTo, 'name') }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <v-layout>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex class="has-border top-left" text-xs-center xs2>
            <h6>Trip Type</h6>
            <div>{{ op(reservation, 'tripType') }}</div>
          </v-flex>
          <v-flex class="has-border" text-xs-center xs2>
            <h6>Distance</h6>
            <div>
              {{ metersToMilesString(op(reservation, 'distance')) }}
            </div>
          </v-flex>
          <v-flex class="has-border" text-xs-center xs2>
            <h6>Travel Time</h6>
            <div>
              {{ travelTime(op(reservation, 'drivingTime')) }}
              Hrs. / {{ op(reservation, 'calendarDays') }} day(s)
            </div>
          </v-flex>
          <v-flex class="has-border" text-xs-center xs2>
            <h6>Vehicles</h6>
            <v-tooltip top>
              <template #activator="action">
                <div class="vehicle-count-tooltip-activator" v-on="action.on">
                  {{ requiredVehiclesCount }}
                </div>
              </template>
              <ul class="vehicle-tooltip-list">
                <li
                  v-for="vehicle in requiredVehicles"
                  :key="vehicle.vehicleType"
                >
                  {{ op(vehicle, 'vehicleType/label') }}
                  - {{ vehicle.quantity }}
                </li>
              </ul>
            </v-tooltip>
          </v-flex>
          <v-flex class="has-border" text-xs-center xs2>
            <h6>Drivers</h6>
            <div>{{ requiredDrivers }}</div>
          </v-flex>
          <v-flex class="has-border top-right" text-xs-center xs2>
            <div v-if="isOwner && reservation.passengerCount">
              <h6>Passengers</h6>
              <div>{{ op(reservation, 'passengerCount') }}</div>
            </div>
            <template v-else>
              <div v-if="op(reservation, 'referralPassengerCount')">
                <h6>Passengers</h6>
                <div>{{ op(reservation, 'referralPassengerCount') }}</div>
              </div>
            </template>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex class="has-border bottom-left" text-xs-left xs6>
            <h6>Trip Notes</h6>
            <div>{{ op(reservation, 'customerNotes') }}</div>
          </v-flex>
          <v-flex class="has-border bottom-right" text-xs-left xs6>
            <span v-if="isOwner">
              <h6>Office Notes</h6>
              <div>{{ op(reservation, 'tripNotes') }}</div>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <v-layout row wrap>
      <v-flex xs6>
        <template v-if="hasRequirements">
          <v-layout row wrap>
            <v-flex text-xs-left xs12>
              <h6>Requirements</h6>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex text-xs-left>
              <div v-if="reservation.spab" class="requirements-border">
                <span>SPAB</span>
              </div>
              <div v-if="reservation.mountain" class="requirements-border">
                <span>Mountain</span>
              </div>
              <div v-if="reservation.ada" class="requirements-border">
                <span>ADA</span>
              </div>
            </v-flex>
          </v-layout>
        </template>
        <v-layout row>
          <v-flex text-xs-left class="max-w-72">
            <h6>Itinerary</h6>
          </v-flex>
          <v-flex v-if="reservation.tripVehicleNeededEntireTrip">
            <CRIcon
              :key="`vehicles-remain-onsite-icon`"
              view-box="0 0 24 19"
              :width="18"
              :height="18"
              class="text-error-new"
            >
            bus_alert_warn
            </CRIcon>
            <span class="whitespace-no-wrap text-error-new">
              Vehicles to remain on-site
            </span>
          </v-flex>
        </v-layout>
        <QuoteFormTripSummaryByStops
          v-if="isShuttleCreateQuoteEnabled && tripVehicleGroups.length"
          :trip-addresses="tripAddresses"
          :trip-vehicle-groups="tripVehicleGroups"
        />
        <div v-else>
          <v-layout row wrap>
            <v-flex text-xs-left xs1>
              <span class="font-weight-light">Stop</span>
            </v-flex>
            <v-flex text-xs-left xs5>
              <span class="font-weight-light">Address</span>
            </v-flex>
            <v-flex text-xs-left xs3>
              <span class="font-weight-light">Dropoff</span>
            </v-flex>
            <v-flex text-xs-left xs3>
              <span class="font-weight-light">Pickup</span>
            </v-flex>
          </v-layout>
          <v-layout v-for="(stop, key) in stops" :key="key" row wrap>
            <v-flex text-xs-left xs1>
              {{ key + 1 }}
            </v-flex>
            <v-flex text-xs-left xs5>
              {{ op(stop, 'address/title') }}
              <br v-if="op(stop, 'address/title')" />
              <div v-if="op(stop, 'address/name')">
                {{ op(stop, 'address/name') }}
              </div>
              <div v-else>
                {{ op(stop, 'address/street1') }}
                {{ op(stop, 'address/street2') }}
                {{ op(stop, 'address/city') }},
                {{ op(stop, 'address/state') }}
                {{ op(stop, 'address/postalCode') }}
              </div>
            </v-flex>
            <v-flex text-xs-left xs3>
              <span v-if="stop.dropoffDatetime" :key="stop.id">
                {{
                  DateTime.fromISO(op(stop, 'dropoffDatetime'), {
                    zone: op(stop, 'address/timeZone'),
                  }).toLocaleString(DateTime.DATE_SHORT)
                }}
                <br />
                {{
                  DateTime.fromISO(op(stop, 'dropoffDatetime'), {
                    zone: op(stop, 'address/timeZone'),
                  }).toLocaleString(DateTime.TIME_SIMPLE)
                }}
              </span>
            </v-flex>
            <v-flex text-xs-left xs3>
              <span
                v-if="
                  childReservation.parentSpotTimes !== null &&
                  parentSpotTimeStops.includes(stop.id)
                "
              >
                [Spot: {{ parentSpotTimeText(stop) }}]
                <br />
              </span>
              <span
                v-if="
                  childReservation.spotTimes !== null &&
                  spotTimeStops.includes(stop.id)
                "
              >
                [Spot: {{ childSpotTimeText(stop) }}]
                <br />
              </span>
              <span v-if="stop.pickupDatetime" :key="stop.id">
                {{
                  DateTime.fromISO(op(stop, 'pickupDatetime'), {
                    zone: op(stop, 'address/timeZone'),
                  }).toLocaleString(DateTime.DATE_SHORT)
                }}
                <br />
                {{
                  DateTime.fromISO(op(stop, 'pickupDatetime'), {
                    zone: op(stop, 'address/timeZone'),
                  }).toLocaleString(DateTime.TIME_SIMPLE)
                }}
              </span>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex text-xs-right xs6>
        <div class="map-container">
          <GmapMap
            ref="gMap"
            :center="mapCenter"
            :options="{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              styles: mapStyles,
            }"
            map-type-id="roadmap"
            style="width: 100%; height: 100%"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import op from 'simple-object-path'
import { phoneFormatFilter } from '@/utils/phone'
import { metersToMilesString } from '@/utils/distance'
import { mapStyles } from '@/components/mapStyles'
import { gmapApi } from 'vue2-google-maps'
import { authComputed } from '@/state/helpers'
import { mapGetters } from 'vuex'
import QuoteFormTripSummaryByStops from './QuoteFormTripSummaryByStops.vue'
import { TripVehicleGroup } from '@/classes/TripVehicleGroup'
import { TripDate } from '@/classes/TripDate'

export default {
  components: {
    QuoteFormTripSummaryByStops,
  },
  props: {
    reservation: {
      type: Object,
      default: () => {},
    },
    childReservation: {
      type: Object,
      default: () => undefined,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      op,
      phoneFormatFilter,
      DateTime,
      metersToMilesString,
      mapStyles,
      requiredVehiclesCount: 0,
      requiredVehicles: [],
      requiredDrivers: 0,
      stops: [],
      // google: gmapApi,
      map: null,
      mapCenter: { lat: 10, lng: 10 },
      isOwner: false,
    }
  },
  computed: {
    ...mapGetters({
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    tripVehicleGroups() {
      let tripVehicleGroups = []
      if (this.reservation.tripVehicleGroups) {
        this.reservation.tripVehicleGroups.forEach((vehicleGroupData) => {
          const tripVehicleGroupHash =
            vehicleGroupData.tripVehicleGroupHash?.toString() ||
            vehicleGroupData.tripVehicleGroupId?.toString()
          const tripVehicleGroup = new TripVehicleGroup({
            tripVehicleGroupHash,
            tripVehicleGroupIndex: tripVehicleGroups.length,
            tripVehicleGroupId: vehicleGroupData.tripVehicleGroupId,
          })
          const filteredStops = this.reservation.stops.filter((stop) => {
            const id = stop.tripVehicleGroupHash || stop.tripVehicleGroupId
            return id?.toString() === tripVehicleGroupHash
          })
          tripVehicleGroup.tripVehicleGroupStops = filteredStops
          let datetimesByTripAddressHash = {}
          for (const stop of filteredStops) {
            const tripAddressHash =
              stop.tripAddressHash?.toString() || stop.tripAddressId?.toString()
            if (
              tripAddressHash &&
              !datetimesByTripAddressHash.hasOwnProperty(tripAddressHash)
            ) {
              datetimesByTripAddressHash[tripAddressHash] = {}
            }
            const date = DateTime.fromISO(
              stop.pickupDatetime || stop.dropoffDatetime,
              {
                zone: stop.address.timeZone,
              }
            ).toFormat('yyyy-MM-dd')
            if (
              !datetimesByTripAddressHash[tripAddressHash].hasOwnProperty(date)
            ) {
              datetimesByTripAddressHash[tripAddressHash][date] = []
            }
            const time = DateTime.fromISO(
              stop.pickupDatetime || stop.dropoffDatetime,
              {
                zone: stop.address.timeZone,
              }
            ).toFormat('HH:mm')
            datetimesByTripAddressHash[tripAddressHash][date].push(time)
          }
          for (const [tripAddressHash, timesByDate] of Object.entries(
            datetimesByTripAddressHash
          )) {
            for (const [date, times] of Object.entries(timesByDate)) {
              let tripDate = new TripDate(tripAddressHash)
              tripDate.date = date
              tripDate.times = times
              tripVehicleGroup.tripDates.push(tripDate)
            }
          }
          tripVehicleGroup.name = vehicleGroupData.name
          tripVehicleGroups.push(tripVehicleGroup)
        })
      }
      return tripVehicleGroups
    },
    tripAddresses() {
      return (
        this.reservation?.tripAddresses.map((tripAddress) => {
          return {
            ...tripAddress,
            tripAddressHash: tripAddress.tripAddressId,
          }
        }) || []
      )
    },
    mapConfig() {
      return {
        styles: mapStyles,
        center: { lat: 0, lng: 0 },
      }
    },
    spotTimeStops() {
      const stopsInSpotTimes = []
      this.childReservation.spotTimes.map((time) => {
        stopsInSpotTimes.push(time.stopId)
      })
      return stopsInSpotTimes
    },
    parentSpotTimeStops() {
      const stopsInSpotTimes = []
      this.childReservation.parentSpotTimes.map((time) => {
        stopsInSpotTimes.push(time.stopId)
      })
      return stopsInSpotTimes
    },
    hasRequirements() {
      return (
        this.reservation.mountain ||
        this.reservation.spab ||
        this.reservation.ada
      )
    },
    google: gmapApi,
    ...authComputed,
  },
  watch: {
    async reservation() {
      await this.setReservation()
    },
  },
  async mounted() {
    await this.setReservation()
  },
  methods: {
    setReservation() {
      this.isOwner = !this.childReservation?.parentReservationId
      if (this.isOwner) {
        this.requiredVehicles = this.reservation?.requiredVehicles || []
        this.requiredVehiclesCount = this.requiredVehicles
          .map((vehicle) => parseInt(vehicle?.quantity, 10))
          .filter((vehicleCount) => vehicleCount)
          .reduce((a, b) => a + b, 0)
        this.requiredDrivers = this.reservation?.requiredDrivers
      } else {
        this.requiredVehicles = this.childReservation?.requiredVehicles || []
        this.requiredVehiclesCount = this.requiredVehicles
          .map((vehicle) => parseInt(vehicle?.quantity, 10))
          .filter((vehicleCount) => vehicleCount)
          .reduce((a, b) => a + b, 0)
        this.requiredDrivers = this.childReservation?.driverCount
      }
      const stops = this.reservation?.stops || []
      this.stops = stops
      this.$gmapApiPromiseLazy().then(this.loadMap)
    },
    travelTime(time) {
      return Number(
        Duration.fromObject({
          seconds: time,
        }).as('hours')
      ).toFixed(2)
    },
    childSpotTimeText(stop) {
      const stopId = stop?.id
      return DateTime.fromISO(
        this.childReservation.spotTimes.filter(
          (time) => time.stopId === stopId
        )[0].spotTime,
        {
          zone: stop?.address?.time_zone,
        }
      ).toLocaleString(DateTime.TIME_SIMPLE)
    },
    parentSpotTimeText(stop) {
      const stopId = stop?.id
      return DateTime.fromISO(
        this.childReservation.parentSpotTimes.filter(
          (time) => time.stopId === stopId
        )[0].spotTime,
        { zone: stop?.address?.time_zone }
      ).toLocaleString(DateTime.TIME_SIMPLE)
    },
    referredTo() {
      const referrals = this.reservation?.referredTo || []
      const firstActiveReferral = referrals.find(
        (referral) => referral?.referralStatus !== 'rejected'
      )
      return firstActiveReferral
    },
    loadMap() {
      const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let labelIndex = 0
      const directionsService = new google.maps.DirectionsService()
      const plotlineData = this.stops.map((s) => ({
        location: {
          lat: s?.address?.lat,
          lng: s?.address?.lng,
        },
      }))
      if (plotlineData.length === 0) {
        return
      }
      const markers = plotlineData.map(
        (data) =>
          new google.maps.Marker({
            position: data.location,
            label: labels[labelIndex++ % labels.length],
          })
      )
      const bounds = new google.maps.LatLngBounds()
      const polylineOptions = {
        strokeColor: this.$cr.theme.primary,
        strokeWeight: 6,
        zIndex: 5,
      }
      const directionsDisplay = new google.maps.DirectionsRenderer({
        polylineOptions,
        preserveViewport: true,
        suppressMarkers: true,
      })

      if (this.$refs.gMap == null) {
        return
      }

      this.$refs.gMap.$mapPromise.then((map) => {
        markers.forEach((marker) => marker.setMap(map))
        const directionsComplete = (directionsData, status) => {
          if (status === 'OK') {
            directionsDisplay.setMap(map)
            directionsDisplay.setDirections(directionsData)
            map.fitBounds(bounds.union(directionsData.routes[0].bounds))
            // plotCompleted()
          }
        }

        const directionsOptions = {
          waypoints: plotlineData,
          origin: plotlineData[0],
          destination: plotlineData[plotlineData.length - 1],
          travelMode: 'DRIVING',
        }
        directionsService.route(directionsOptions, directionsComplete)
      })
    },
  },
}
</script>

<style lang="scss">
.requirements-border {
  border: 1px solid gray;
  border-radius: 4px;
  display: inline-flex;
  margin: 4px;
  span {
    padding: 2px 8px 1px 8px !important;
  }
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}

.map-container {
  width: 100%;
  height: 300px;
}

.vehicle-tooltip-list {
  padding: 0;
  list-style: none;
}

.vehicle-count-tooltip-activator {
  color: $primary;
}
</style>
