<template>
  <Comment
    :comments="comments"
    @cancel="() => $emit('refresh-query-request')"
    @save="addReservationComment"
  />
</template>

<script>
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import Comment from '@/components/Comment.vue'

export default {
  components: {
    Comment,
  },
  props: {
    reservationId: { type: Number, default: () => null },
    reservationComments: { type: Array, default: () => [] },
  },
  data() {
    return {
      note: '',
      validNote: true,
      formSubmitAttempt: false,
    }
  },
  computed: {
    comments() {
      const sortByDate = (a, b) =>
        DateTime.fromISO(a.createdOn) > DateTime.fromISO(b.createdOn) ? -1 : 0
      const commentsOut = deepClone(this.reservationComments)
      commentsOut.sort(sortByDate)
      return commentsOut
    },
  },
  methods: {
    async addReservationComment(note) {
      const params = {
        reservation: {
          id: this.reservationId,
        },
        note,
      }
      await this.$store.dispatch('reservations/addReservationComment', params)
      this.$emit('refresh-query-request')
    },
  },
}
</script>
