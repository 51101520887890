import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { HttpService } from '@/services/common/HttpService'
import { Driver } from '@/models/dto/Driver'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getDrivers(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Driver>>> {
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      additionalQueries = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/drivers?${query}`
    return httpService.get(url)
  },
  getAllDriversForCompany(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Driver>>> {
    const { pageSize = -1, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}&referredCompanyId=${params.companyId}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/drivers?${query}`
    return httpService.get(url)
  },
  getDriver(driverId: number): Promise<AxiosResponse<Driver>> {
    const host = baseUrl()
    const url = `https://${host}/v3/drivers/${driverId}`
    return httpService.get(url)
  },
  modifyDriver(params): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/user/${params.id}`

    return httpService.patch(url, params.payload)
  },
  modifyDriverV3(params): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/v3/drivers/${params.id}`
    return httpService.put(url, params.payload)
  },
  addDriver(params): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/v3/drivers`

    return httpService.post(url, params.payload)
  },
  makeDriver(driverId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/v3/drivers/makeDriver/${driverId}`

    return httpService.get(url)
  },
  activateDriver(driverId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/drivers/activateDriver/${driverId}`

    return httpService.get(url)
  },
  deactivateDriver(driverId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/drivers/deactivate/${driverId}`

    return httpService.get(url)
  },
  uploadDriverPhoto(params: { driverId: number, payload: any}): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/user/${params.driverId}/uploadPhoto?primaryImage=true`

    return httpService.post(url, params.payload)
  },
  deleteDriver(driverId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/v3/drivers/${driverId}`
    return httpService.delete(url)
  },
  getAffiliateDetails(driverId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/drivers/${driverId}/affiliatesDetail`
    return httpService.get(url)
  },
}
