<template>
  <div class="unavailability-override-sidebar">
    <span
      class="unavailability-override-sidebar--content"
      :style="`color: ${
        conflictStatus === 'partial' ? $cr.theme.yellow : $cr.theme.red
      }`"
    >
      <CRIcon view-box="0 0 24 24" :width="25" :height="25" class="margin-r-2">
        alert_outline
      </CRIcon>
      {{ alertText }}
    </span>
    <v-layout justify-start>
      <div class="unavailability-override-sidebar--btn-spacer">
        <v-btn
          id="send-button"
          class="unavailability-override-sidebar--action-btn"
          color="primary"
          @click="$emit('send-referral')"
        >
          Send Referral
        </v-btn>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { ProviderConflict } from '@/utils/enum'

export default {
  props: {
    conflictStatus: {
      type: String,
      default: null,
    },
  },
  computed: {
    alertText() {
      let result
      if (this.conflictStatus === ProviderConflict.Partial) {
        result = 'Less vehicles available than the trip requires. '
      } else {
        result = 'No available vehicles for this trip date. '
      }
      result += 'Override provider unavailability and send referral?'
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.unavailability-override-sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 500px;

  &--content {
    margin: 25px;
    display: flex;
    align-items: center;
  }

  &--btn-spacer {
    min-height: 71px;
    width: 500px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    z-index: 3;
    margin: 0 !important;
    border-radius: 0 !important;
  }
}
</style>
