<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <slot name="activator" :on="on">
        <v-btn
          id="mark-referral-accepted"
          class="offer-btn btn-primaryaction"
          :loading="loading"
          :large="large"
          @click="openDialog"
        >
          Mark Accepted
        </v-btn>
      </slot>
    </template>
    <v-card class="cr-modal">
      <v-card-title>
        <h2>Approve {{ reservationId }}</h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-layout align-center row wrap>
            <v-flex xs12>
              <div class="pb-1">Customer Account Referral Terms</div>
              <div class="terms-text-area">
                {{ customReferralTerms }}
              </div>
            </v-flex>
            <v-flex xs12 class="pt-3">
              <CRInput
                id="customer-account-referral-terms-text-terms-name"
                v-model="yourFullName"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Your Full Name Is Required',
                    error: 'Your Full Name Is Required',
                  }),
                ]"
                placeholder="Please type your full name"
              />
            </v-flex>
            <v-flex xs12>
              <v-layout align-start>
                <v-checkbox
                  id="customer-account-referral-terms-checkbox-use-custom-terms"
                  v-model="acceptCustomCustomerAccountToS"
                  :rules="[(val) => val || 'Must be accepted.']"
                  class="shrink mt-0 mb-2"
                  solo
                  readonly
                  hide-details
                  @click.stop="
                    acceptCustomCustomerAccountToS = !acceptCustomCustomerAccountToS
                  "
                ></v-checkbox>
                <v-flex style="margin-top: 13px">
                  I agree to the {{ referralTermsName }}
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout align-start>
                <v-checkbox
                  id="customer-account-referral-terms-checkbox-use-custom-terms"
                  v-model="acceptCharterUpToS"
                  :rules="[(val) => val || 'Must be accepted.']"
                  class="shrink mt-0 mb-2"
                  solo
                  readonly
                  hide-details
                  @click.stop="acceptCharterUpToS = !acceptCharterUpToS"
                ></v-checkbox>
                <v-flex style="margin-top: 13px">
                  I agree to the
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.charterup.com/terms-and-conditions-for-providers.pdf"
                  >
                    CharterUP Terms & Conditions for Providers
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :id="`${id}-mark-accepted-button-reject`"
          color="error"
          flat
          outline
          style="background-color: white !important"
          @click="reject"
        >
          Reject
        </v-btn>
        <v-spacer />
        <v-btn
          :id="`${id}-mark-accepted-button-cancel`"
          flat
          outline
          color="primary"
          style="background-color: white !important"
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :id="`${id}-mark-accepted-button-accept`"
          color="primary"
          @click="accept"
        >
          Accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    reservationId: {
      type: String,
      default: null,
    },
    customerAccount: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      acceptCharterUpToS: false,
      acceptCustomCustomerAccountToS: false,
      yourFullName: '',
      enterpriseAccount: null,
    }
  },
  computed: {
    useCustomReferralTerms() {
      return (
        this.enterpriseAccount?.useCustomReferralTerms ||
        this.customerAccount?.useCustomReferralTerms
      )
    },
    customReferralTerms() {
      return (
        this.enterpriseAccount?.referralTerms ||
        this.customerAccount?.referralTerms
      )
    },
    referralTermsName() {
      return (
        this.enterpriseAccount?.referralTermsName ||
        this.customerAccount?.referralTermsName
      )
    },
    hasButtonSlot() {
      return !!this.$slots.activator
    },
  },
  watch: {
    customerAccount: {
      immediate: true,
      async handler() {
        const enterpriseAccountId = this.customerAccount?.enterpriseAccountId
        if (enterpriseAccountId) {
          const { data } = await customerAccounts.getCustomerAccount(
            enterpriseAccountId
          )
          this.enterpriseAccount = data
        }
      },
    },
  },
  methods: {
    isNotEmpty,
    isRequired,
    validateEmail,
    openDialog() {
      if (!this.useCustomReferralTerms) {
        this.$emit('accept')
        return
      }
      this.dialog = true
    },
    accept() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.dialog = false
      this.$emit('accept', this.yourFullName)
    },
    reject() {
      this.dialog = false
      this.$emit('reject')
    },
  },
}
</script>

<style lang="scss" scoped>
.offer-btn {
  min-height: 40px;
  color: $white;
}

.terms-text-area {
  height: 300px;
  overflow: scroll;
  background-color: $blue-dull;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
}
</style>
