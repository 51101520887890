import { render, staticRenderFns } from "./ReferralsList.vue?vue&type=template&id=038bf32e&scoped=true"
import script from "./ReferralsList.vue?vue&type=script&lang=js"
export * from "./ReferralsList.vue?vue&type=script&lang=js"
import style0 from "./ReferralsList.vue?vue&type=style&index=0&id=038bf32e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038bf32e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAutocomplete,VBtn,VBtnToggle,VContainer,VDataTable,VFlex,VIcon,VLayout,VRadio,VRadioGroup,VSheet})
