import { baseUrl } from '@/utils/env'
import axios, { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { Trip, VehicleAssignmentResult, OverridePaymentTermsPayload, Reservation, UpdatePurchaseOrderRequestDTO } from '@/models/dto'
import { TripMonitoringTableResult } from '@/models/dto/TripMonitoring'

const httpService: HttpService = new HttpService()

export const reservationsTableView = (query) =>
  httpService.get(`https://${baseUrl()}/tables/referrals?${query}`, {
    headers: { 'Cache-Control': 'private, no-cache, no-store, max-age=0' },
  })

export const reservationById = (managedId: string): Promise<AxiosResponse<Reservation>> => {
  const url = `https://${baseUrl()}/reservations/v2/${managedId}?viewRejectedReferrals=true`
  return httpService.get(url)
}

export const getAvailableDriversForRes = (
  reservationIds: number[],
  companyId: number
) => {
  const url = `https://${baseUrl()}/tripAssignments/availableDrivers/${companyId}`
  return httpService.post(url, reservationIds)
}

export const getAvailableVehiclesForRes = (
  reservationIds: number[],
  companyId: number
) => {
  const url = `https://${baseUrl()}/tripAssignments/availableVehicles/${companyId}`
  return httpService.post(url, reservationIds)
}

export const getUnavailableDriversForRes = (
  reservationIds: number[],
  companyId: number
) => {
  const url = `https://${baseUrl()}/tripAssignments/unavailableDrivers/${companyId}`

  return httpService.post(url, reservationIds)
}

export const getUnavailableVehiclesForRes = (
  reservationIds: number[],
  companyId: number
) => {
  const url = `https://${baseUrl()}/tripAssignments/unavailableVehicles/${companyId}`
  return httpService.post(url, reservationIds)
}

export const validateTrip = (trip: Trip) => {
  const url = `https://${baseUrl()}/tripAssignments/validateTrip`
  return httpService.post(url, trip)
}

export const deleteTripAssignmentsWithIds = (assignmentIds: number[]) => {
  const url = `https://${baseUrl()}/tripAssignments/deleteAssignments`
  return httpService.delete(url, {
    data: { deleteAssignmentIds: assignmentIds },
  })
}

export const createTripAssignment = (payload) => {
  const url = `https://${baseUrl()}/tripAssignments/createAssignment`
  return httpService.post(url, payload)
}

export const sendDriverInfoNotifications = (reservationId) => {
  const url = `https://${baseUrl()}/reservations/driverInfoNotifications/${reservationId}`
  return httpService.get(url)
}

export const deleteMassAssignment = (payload) => {
  const url = `https://${baseUrl()}/massAssignments/remove`
  return httpService.delete(url, { data: payload })
}

export const createMassAssignment = (payload) => {
  const url = `https://${baseUrl()}/massAssignments/create`
  return httpService.post(url, payload)
}

export const updateAssignments = (payload) => {
  const url = `https://${baseUrl()}/trips/validateTrip`
  return httpService.post(url, payload)
}

export const updateClassifications = (payload): Promise<AxiosResponse> => {
  const url = `https://${baseUrl()}/reservations/classifications`
  return httpService.patch(url, payload)
}

export const updateTier = (payload): Promise<AxiosResponse> => {
  const url = `https://${baseUrl()}/reservations/tier`
  return httpService.patch(url, payload)
}

export const updateSpotTimes = (reservationId: number, spotTimes) => {
  const url = `https://${baseUrl()}/reservations/${reservationId}/spotTimes`
  return httpService.patch(url, spotTimes)
}

export const detectVehicleConflict = (payload) => {
  const url = `https://${baseUrl()}/tripAssignments/detectVehicleConflict`
  return httpService.post(url, payload)
}

export const deleteSpotTime = (spotTime) => {
  const url = `https://${baseUrl()}/reservations/spotTime`
  return httpService.delete(url, spotTime)
}

export const getTripAssignmentsForReservation = async (payload): Promise<AxiosResponse<VehicleAssignmentResult>> => {
  const url = `https://${baseUrl()}/tripAssignments/reservation`

  const response: AxiosResponse<VehicleAssignmentResult> = await httpService.post(
    url,
    payload
  )
  // Rename simulated vehicles
  const tripsAssignmentsForReservation = response.data.vehicleAssignments
  for (const tripAssignment of tripsAssignmentsForReservation) {
    if (tripAssignment.vehicle.simulated) {
      tripAssignment.vehicle.vehicleModel = ''
      if (
        !tripAssignment.driverAssignments ||
        tripAssignment.driverAssignments.length === 0
      ) {
        tripAssignment.vehicle.vehicleName = 'Unassigned Vehicle '
      } else if (tripAssignment.driverAssignments.length === 1) {
        const driver = tripAssignment.driverAssignments[0].driver
        tripAssignment.vehicle.vehicleName = `Unassigned Vehicle (${driver.firstName})`
      } else {
        const driverNames = tripAssignment.driverAssignments
          .map((da) => da.driver.firstName.slice(0, 4) + '...')
          .join(', ')
        tripAssignment.vehicle.vehicleName = `Unassigned Vehicle (${driverNames})`
      }
    }
  }

  return response
}

export const getTrackingHistoryV2 = (
  payload,
  minutesBefore: number = 65,
  minutesAfter: number = 60
) => {
  const host = baseUrl()
  const url = `https://${host}/v2/tracking/data?minutesBefore=${minutesBefore}&minutesAfter=${minutesAfter}`
  return httpService.post(url, payload)
}

export const getCancellationConfirmation = (
  reservationId: number
): Promise<AxiosResponse<{ cancellationConfirmation: any }>> => {
  const host = baseUrl()
  const url = `https://${host}/reservations/${reservationId}/getCancellationConfirmationDetails`
  return httpService.get(url)
}

export const validateMassAssignmentEligibility = (payload) => {
  const url = `https://${baseUrl()}/massAssignments/validate`
  return httpService.post(url, payload)
}

export const getClassifications = (label: string): Promise<AxiosResponse> => {
  const host = baseUrl()
  const url = `https://${host}/classification/${label}`
  return httpService.get(url)
}

export const getRoutesForReservations = (reservationIds: number[], fetchChildren: boolean = false): Promise<AxiosResponse<any>> => {
  const url = `https://${baseUrl()}/reservations/tripVehicleGroups?fetchChildren=${fetchChildren}`
  return httpService.post(url, { reservationIds })
}

export const getReservationClassifications = async (reservationId: number) => {
  const response: AxiosResponse<any> = await httpService.get(
    `https://${baseUrl()}/reservations/${reservationId}/classifications`
  )
  if (response && response.data && response.data.successful === true) {
    return response.data.classifications
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error(
      'Unspecified error while getting reservation classifications.'
    )
  }
}

export const createReservationClassification = async (
  reservationId: number,
  classificationId: number
) => {
  const response: AxiosResponse<any> = await httpService.put(
    `https://${baseUrl()}/reservations/${reservationId}/classifications/${classificationId}`,
    null
  )
  if (response && response.data && response.data.successful === true) {
    return response
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error(
      'Unspecified error while creating reservation classification.'
    )
  }
}

export const shareReservation = async (
  reservationId: number,
  userId: number
) => {
  const url = `https://${baseUrl()}/reservations/${reservationId}/share/${userId}`
  return httpService.post(url, {})
}

export const unshareReservation = async (
  reservationId: number,
  userId: number
) => {
  const url = `https://${baseUrl()}/reservations/${reservationId}/unshare/${userId}`
  return httpService.post(url, {})
}

export const getReservationsForContractTracking = async (payload: {
  contractQuoteId: number
  date: string
  timeZone: string
  status: Array<string>
}) => {
  let query = ''
  const queryMap = new Map()
  if (payload.date) {
    queryMap.set('date', payload.date)
  }
  if (payload.timeZone) {
    queryMap.set('timeZone', payload.timeZone)
  }
  if (payload.status?.length) {
    queryMap.set('status', payload.status.join(','))
  }
  if (queryMap.size) {
    query = `?${Array.from(queryMap)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`
  }
  const url = `https://${baseUrl()}/coachrail/v1/contracts/${
    payload.contractQuoteId
  }/reservations${query}`
  return httpService.get(url)
}

export const getReservationDetailForContractTracking = async (
  contractQuoteId: number,
  reservationId: number
) => {
  const url = `https://${baseUrl()}/coachrail/v1/contracts/${contractQuoteId}/reservations/${reservationId}`
  return httpService.get(url)
}

export const overridePaymentTerms = async (payload: OverridePaymentTermsPayload) => {
  const url =`https://${baseUrl()}/reservations/overridePaymentTerms`
  return httpService.post(url, payload)
}

export const recalculateAssignmentPercentages = async (
  reservationId: number
) => {
  const url = `https://${baseUrl()}/reservations/${reservationId}/recalculateAssignmentPercentages`
  return httpService.post(url, {})
}

export const markTripModificationRequestComplete = async (managedId: string) => {
  const url = `https://${baseUrl()}/coachrail/v1/reservation/complete-trip-modification`
  return httpService.post(url, {
    managedId
  })
}

export const getTripMonitoringReferrals = async (
  start: string,
  end: string
): Promise<AxiosResponse<TripMonitoringTableResult>> => {
  const url = `https://${baseUrl()}/tripMonitoring/table?start=${start}&end=${end}`
  return httpService.get(url)
}

export const getTripMonitoringDetail = async (
  reservationId: number
) => {
  const url = `https://${baseUrl()}/tripMonitoring/detail/${reservationId}`
  return httpService.get(url)
}

export const updateReservationPurchaseOrder = async (reservationExternalId: string, payload: UpdatePurchaseOrderRequestDTO) => {
  const url = `https://${baseUrl()}/reservations/${reservationExternalId}/purchaseOrder`
  return httpService.put(url, payload)
}
