import { DateTime, Duration } from 'luxon'


export const formatGarageTimeISO = (date, onlyTime, timeZone) => {
  const ISO = `${date}T${onlyTime}`
  if (timeZone !== null) {
    const timeString = DateTime.fromISO(ISO, {
      zone: timeZone,
    }).toISO()
    return timeString
  }

  const timeString = DateTime.fromISO(ISO).toISO()
  return timeString
}

export const convertDurationToMinutesInteger = (time) => {
  let timeDuration = time.split(':')
  return Duration.fromObject({
    hours: timeDuration[0],
    minutes: timeDuration[1],
    seconds: timeDuration[2],
  }).as('minutes')
}

export const performTimeAtYardCalculation = (
  preTripArrivalTime,
  departureTimeObj
) => {
  if (!preTripArrivalTime || !departureTimeObj) {
    return null
  }
  let preTripArrivalTimeObj = DateTime.fromISO(preTripArrivalTime)
  let diff = preTripArrivalTimeObj.diff(departureTimeObj, 'minutes')
  return Math.round(Math.abs(diff.minutes) / 5) * 5
}

export const timeAtYardOptions = [
  {
    label: '--',
    value: 0,
  },
  {
    label: '5 Minutes',
    value: 5,
  },
  {
    label: '10 Minutes',
    value: 10,
  },
  {
    label: '15 Minutes',
    value: 15,
  },
  {
    label: '20 Minutes',
    value: 20,
  },
  {
    label: '25 Minutes',
    value: 25,
  },
  {
    label: '30 Minutes',
    value: 30,
  },
  {
    label: '45 Minutes',
    value: 45,
  },
  {
    label: '60 Minutes',
    value: 60,
  },
]
