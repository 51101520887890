<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center">
      <CharterUpLogo :width="150" />
      <h5 style="margin-left: 10px">Referrals List</h5>
    </div>

    <div style="margin-top: 15px; text-align: center">
      <v-btn-toggle
        v-model="marketplaceSelectionIndex"
        mandatory
        style="margin: 25px 12px 0"
      >
        <v-btn
          v-for="(option, index) in marketplaceSelectionOptions"
          :key="option.label"
          flat
          @click="onMarketplaceSelection(index)"
        >
          {{ option.label }}
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle
        v-model="referralCreationMethodSelectionIndex"
        mandatory
        style="margin: 25px 12px 0"
      >
        <v-btn
          v-for="(option, index) in referralCreationMethodSelectionOptions"
          :key="option.label"
          flat
          @click="onReferralCreationMethodSelection(index)"
        >
          {{ option.label }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-container
      grid-list-lg
      text-center
      style="margin-top: 24px; padding-bottom: 0"
    >
      <v-layout
        row
        justify-center
        align-center
        style="margin-top: 25px; margin-bottom: 0"
      >
        <v-flex xs6 sm4 md3>
          <CRInput
            id="from-date-picker"
            v-model="startDate"
            label="From Date"
            type="date"
            name="from-date-picker"
            @change="refresh"
          />
        </v-flex>
        <v-flex xs6 sm4 md3>
          <CRInput
            id="to-date-picker"
            v-model="endDate"
            label="To Date"
            type="date"
            name="to-date-picker"
            @change="refresh"
          />
        </v-flex>
        <v-radio-group
          v-model="dateToFilterOn"
          class="radio-group"
          hide-details
          @change="refresh"
        >
          <v-radio
            color="primary"
            value="booked_on_date"
            label="Booked On Date"
          ></v-radio>
          <v-radio
            color="primary"
            value="pickup_date"
            label="Pickup Date"
          ></v-radio>
        </v-radio-group>
      </v-layout>
    </v-container>

    <v-container style="padding-bottom: 0">
      <v-layout
        justify-center
        align-center
        style="margin-bottom: 25px; margin-top: 10px"
      >
        <div>
          <label>Company</label>
          <v-autocomplete
            id="referral-list-autocomplete-search-company"
            v-model="companySelector"
            :items="affiliateList"
            :search-input.sync="companySearch"
            flat
            solo
            clearable
            hide-no-data
            hide-selected
            item-text="name"
            item-value="companyId"
            placeholder="Start typing to Search"
            append-icon="keyboard_arrow_down"
            return-object
            @click:clear="(evt) => clearAffiliateSearch()"
            @change="companySearch = null"
          />
        </div>
        <div v-show="!fromCancellationsPage" style="margin-left: 15px">
          <label>Agent</label>
          <CRInput
            id="referral-list-autocomplete-search-market"
            v-model="agentName"
            flat
            solo
            clearable
            hide-no-data
            hide-selected
            placeholder="Agent Name"
            style="margin-top: 5px"
            append-icon="keyboard_arrow_down"
            return-object
          />
        </div>
        <div v-show="fromCancellationsPage" style="margin-left: 15px">
          <label>Market</label>
          <v-autocomplete
            id="referral-list-autocomplete-search-market"
            v-model="marketSelector"
            :items="marketList"
            :search-input.sync="marketSearch"
            flat
            solo
            clearable
            hide-no-data
            hide-selected
            item-text="marketName"
            item-value="marketId"
            placeholder="Start typing to Search"
            append-icon="keyboard_arrow_down"
            return-object
            @click:clear="(evt) => clearMarketSearch()"
            @change="marketSearch = null"
          />
        </div>
        <div v-show="fromCancellationsPage" style="margin-left: 15px">
          <label>Cancellation Type</label>
          <v-autocomplete
            id="referral-list-autocomplete-search-classification"
            v-model="classificationSelector"
            :items="classificationList"
            :search-input.sync="classificationSearch"
            flat
            solo
            clearable
            hide-no-data
            hide-selected
            item-text="fullClassificationName"
            item-value="classificationId"
            placeholder="Start typing to Search"
            append-icon="keyboard_arrow_down"
            return-object
            @click:clear="(evt) => clearClassificationSearch()"
            @change="classificationSearch = null"
          />
        </div>
      </v-layout>
    </v-container>

    <div style="margin: 25px 5% 35px; margin-top: 50px; position: relative">
      <v-sheet style="padding: 20px 40px">
        <v-data-table
          :items="tableProps.list"
          :pagination.sync="pagination"
          :total-items="tableProps.total"
          :headers="tableProps.columns"
          :headers-length="tableProps.columns.length"
          :rows-per-page-items="rowsPerPageItems"
          transparent
        >
          <template #headers="props">
            <tr>
              <th
                v-for="(column, key) in props.headers"
                :id="`data-table-header-column-${column.text}`"
                :key="`data-table-header-column-${column.text}`"
                :style="[
                  showCollapseIcon && key === collapseIconIndex
                    ? `padding-left: 30px !important;`
                    : `padding-left: 0px;`,
                ]"
                :class="'header-default'"
                @click="column.sort === true ? initSort(column) : () => true"
              >
                <strong>{{ column.text }}</strong>

                <span :key="`data-table-sort-key${currentSort.key}`">
                  <CRIcon
                    v-if="
                      currentSort &&
                      currentSort.id === column._t_id &&
                      currentSort.direction === 'asc'
                    "
                    :width="16"
                    :height="16"
                    view-box="0 0 24 24"
                    style="margin-bottom: -4px"
                  >
                    arrow_upward
                  </CRIcon>
                  <CRIcon
                    v-else-if="
                      currentSort &&
                      currentSort.id === column._t_id &&
                      currentSort.direction === 'desc'
                    "
                    :width="16"
                    :height="16"
                    view-box="0 0 24 24"
                    style="margin-bottom: -4px"
                  >
                    arrow_downward
                  </CRIcon>
                  <v-icon v-else small style="width: 16px">fa-fw</v-icon>
                </span>
              </th>
            </tr>
          </template>
          <template #items="props">
            <tr :id="`data-table-row-${props.index}`" :class="rowClass(props)">
              <td
                v-for="(col, idx) in tableProps.columns"
                :id="`data-table-row-${props.index}-col-${idx}-button-detail`"
                :key="`data-table-td-${col._t_id}-${idx}-${pagination.page}`"
                :class="[
                  col.dataClass ? col.dataClass : 'data-default',
                  col.shrinkWidth ? 'shrink' : '',
                ]"
              >
                <span
                  v-if="
                    showCollapseIcon &&
                    idx === collapseIconIndex &&
                    !props.expanded
                  "
                >
                  <CRIcon
                    :id="`data-table-row-${props.index}-col-${idx}-button-arrow-down`"
                    style="font-size: 20px; margin-bottom: -6px"
                    material
                  >
                    keyboard_arrow_down
                  </CRIcon>
                </span>
                <span
                  v-else-if="
                    showCollapseIcon &&
                    idx === collapseIconIndex &&
                    props.expanded
                  "
                >
                  <CRIcon
                    :id="`data-table-row-${props.index}-col-${idx}-button-arrow-up`"
                    style="font-size: 20px; margin-bottom: -6px"
                    material
                  >
                    keyboard_arrow_up
                  </CRIcon>
                </span>
                <template v-if="!getColumnComponent(col)">
                  {{ columnDisplay(props.item, fullColumnDefinition(col)) }}
                </template>
                <template v-if="getColumnComponent(col)">
                  <component
                    :is="getColumnComponent(col)"
                    :id="`data-table-row-${props.index}-col-${idx}`"
                    :key="`data-table-page-${currentPage}-row-${props.index}-col-${idx}`"
                    :bubble-action="bubbleAction"
                    :column="fullColumnDefinition(col)"
                    :row="props.item"
                    :row-props="props"
                  />
                </template>
              </td>
            </tr>
          </template>
          <template v-if="loading" slot="footer">
            <tr style="height: 2px">
              <td :colspan="tableProps.columns.length">
                <div
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  class="cr-progress-linear"
                  style="height: 2px"
                >
                  <div class="v-progress-linear__bar">
                    <div
                      class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
                    >
                      <div
                        class="v-progress-linear__bar__indeterminate long primary"
                      />
                      <div
                        class="v-progress-linear__bar__indeterminate short primary"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import uuid, { v4 } from 'uuid'
import op from 'simple-object-path'
import { columnDisplay } from '@/utils/columnDisplay'
import ReservationTableDetail from '@/components/ReservationTableDetail.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import affiliates from '@/services/affiliates'
import classifications from '@/services/classifications'
import markets from '@/services/markets'
import { currencyFilter } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
import { objectDataPaths } from '@/utils/objectDataPaths'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  userPredefined,
} from '@/utils/predefined'
import { capitalize } from '../utils/string'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { setReferralsFilters } from '@/utils/referralsFilters'

const categories = []
const referralStatusMap = [
  { text: 'Not Offered', value: 'not_offered' },
  { text: 'Offered', value: 'fully_offered' },
  { text: 'Accepted', value: 'fully_accepted' },
  { text: 'Confirmed', value: 'fully_confirmed' },
  { text: 'Offered', value: 'offered' },
  { text: 'Accepted', value: 'accepted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Confirmed', value: 'confirmed' },
]

export default {
  metaInfo() {
    return {
      title: 'Referrals',
    }
  },
  data() {
    return {
      loading: false,
      marketplaceSelectionIndex: 0,
      referralCreationMethodSelectionIndex: 0,
      marketplaceSelection: 'all',
      referralCreationMethodSelection: 'all',
      dateToFilterOn: 'pickup_date',
      marketplaceSelectionOptions: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Marketplace',
          value: 'marketplace',
        },
        {
          label: 'Non-Marketplace',
          value: 'non-marketplace',
        },
      ],
      referralCreationMethodSelectionOptions: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'AutoReferred',
          value: 'autoReferred',
        },
        {
          label: 'ReferredOnConvert',
          value: 'referredOnConvert',
        },
        {
          label: 'ManuallyReferred',
          value: 'manuallyReferred',
        },
      ],
      startDate: DateTime.utc().minus({ days: 30 }).toFormat('yyyy-MM-dd'),
      endDate: DateTime.utc().toFormat('yyyy-MM-dd'),
      affiliateList: [],
      companySearch: null,
      companySelector: null,
      marketList: [],
      marketSearch: null,
      marketSelector: null,
      agentName: null,
      classificationList: [],
      classificationSelector: null,
      classificationSearch: null,
      filterCancelledReferrals: false,
      rejectionMethodList: [
        {
          id: 1,
          label: 'Manual',
        },
        {
          id: 2,
          label: 'Timed-Out',
        },
        {
          id: 3,
          label: 'Ignored',
        },
        {
          id: 4,
          label: 'Cancellation',
        },
      ],
      columnDisplay,
      requestIdQueue: [],
      debounce: undefined,
      selectedFilter: undefined,
      filters: [],
      filterUtil: filter(),
      sorts: sort(),
      isAdmin: false,
      pagination: {
        rowsPerPage: 10,
        page: 1,
      },
      rowsPerPageItems: [...Array(10).keys()].map((x) => (x + 1) * 10),
      currentSort: {
        prop: undefined,
        direction: undefined,
      },
      tableProps: {
        enableExport: false,
        enableColumnConfig: true,
        enableSavedViews: false,
        enableStatusFilterToggle: false,
        canViewInactiveReservations: true,
        total: 0,
        tableId: 'referral_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detail: ReservationTableDetail,
        detailKeyId: 'reservationId',
        isDetailed: true,
        shareSorts: this.receiveSorts,
        loading: true,
        categories,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
        enableSelectableRows: false,
        enableSelectAll: false,
        selectedRows: [],
        id: 'referral-list',
        ephemeralFilters: [],
      },
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      referralsTVFilters: 'referrals/referralsTVFilters',
      referralsTVFilterValues: 'referrals/referralsTVFilterValues',
    }),
    fromDate() {
      if (this.startDate) {
        return `${this.startDate} 24:00:00`
      }
      return null
    },
    toDate() {
      if (this.endDate) {
        return `${this.endDate} 24:00:00`
      }
      return null
    },
    fromCancellationsPage() {
      return this.referralsTVFilterValues?.cancelledReferrals
    },
    referralsFilterRowProps() {
      return {
        item: {
          company_id: this.companySelector?.companyId,
          market_id: this.marketSelector?.marketId,
          cancelled_referrals: this.referralsTVFilterValues?.cancelledReferrals,
          agent_name: this.agentName,
        },
      }
    },
    referralsFilterColumn() {
      return {
        cancellationType: this.classificationSelector?.classificationId,
        marketplaceFilter: this.marketplaceSelection,
        referralCreationMethodFilter: this.referralCreationMethodSelection,
        dateToFilterOn: this.dateToFilterOn,
        fromDate: this.startDate,
        toDate: this.endDate,
      }
    },
    currentFilters() {
      let referralFilters = setReferralsFilters(
        this.referralsFilterRowProps,
        this.referralsFilterColumn,
        null
      )

      return referralFilters
    },
  },
  watch: {
    pagination: {
      handler() {
        this.changePage(this.pagination)
      },
    },
    companySearch() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (!this.immediate) {
        this.debounce = setTimeout(() => {
          this.immediate = true
          this.searchAffiliates()
        }, 500)
        return
      }
      this.searchAffiliates()
    },
    companySelector() {
      this.refresh(true)
    },
    marketSelector() {
      this.refresh(true)
    },
    classificationSelector() {
      this.refresh(true)
    },
    agentName() {
      this.refresh()
    },
  },
  async mounted() {
    const user = this.currentUser
    this.tableProps.isAdmin = user?.group?.key === 'admin'

    await this.getColumns()

    await this.getClassifications()
    await this.getMarketList()

    await this.setFiltersFromStore()

    this.filterUtil.set(this.currentFilters)

    this.refresh()

    this.tableProps.ephemeralFilters = this.referralsTVFilters
    this.$store.dispatch('referrals/clearReferralsFilters')

    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
    EventBus.$on('successful-assignment', (event) => {
      this.refresh()
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations updated.`,
        })
      }, 1)
    })
    EventBus.$on('successful-clear-assignments', (event) => {
      this.refresh()
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `Assignments for (${event.length}) reservations have been cleared.`,
        })
      }, 1)
    })
    EventBus.$on('successful-add-driver', (event) => {
      setTimeout(() => {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: `A new driver ${event} has been created.`,
        })
      }, 1)
    })
  },
  methods: {
    clearAffiliateSearch() {
      this.companySearch = null
      this.companySelector = null
    },
    clearMarketSearch() {
      this.marketSearch = null
      this.marketSelector = null
    },
    clearClassificationSearch() {
      this.classificationSearch = null
      this.classificationSelector = null
    },
    async getClassifications() {
      const CANCELLATION_CLASSIFICATON_TYPE_ID = 2

      const filterObjects = []
      const classificationSort = sort()
      const classificationFilter = filter()

      filterObjects.push({
        column: {
          _t_id: '4d64ac73-1be4-4cd3-ae81-b8fa0cfcd2f0',
          prop: 'classificationTypeId',
          type: 'number',
          filterType: 'eq',
          filter: true,
        },
        value: CANCELLATION_CLASSIFICATON_TYPE_ID,
      })

      filterObjects.forEach((fil) => {
        const parent = classificationFilter.createParent('and')
        classificationFilter.add(parent, fil)
      })

      const filterParams = classificationFilter.asQueryParams()
      const sortParams = classificationSort.asQueryParams()

      this.classificationList = await classifications
        .getClassifications({
          filters: filterParams,
          sort: sortParams,
          page: 1,
          pageSize: -1,
        })
        .then(({ data }) => {
          return data.resultList.filter(
            (item) => item.parentClassifications !== null
          )
        })
        .catch((e) => {})

      this.classificationList.map(
        (item) =>
          (item.fullClassificationName =
            item.parentClassifications + ' - ' + item.classificationName)
      )

      this.classificationList = [
        {
          fullClassificationName: 'None Selected',
          classificationName: 'None Selected',
          classificationId: -1,
        },
      ].concat(this.classificationList)
    },
    async getMarketList() {
      const marketsData = await markets.tableView({
        pageSize: -1,
      })
      this.marketList = marketsData?.data?.resultList || []
      this.marketList.sort((a, b) => (a.marketName > b.marketName ? 0 : -1))
    },
    async searchAffiliates() {
      this.immediate = false

      const filterObjects = []
      const affilSort = sort()
      const affilFilter = filter()

      if (this.companySearch) {
        filterObjects.push({
          column: {
            _t_id: 'text_search_refer',
            prop: 'name',
            filterType: 'contains',
          },
          value: this.companySearch,
        })
      }

      affilSort.add({
        prop: 'referralCount',
        direction: 'desc',
      })

      filterObjects.forEach((fil) => {
        const parent = affilFilter.createParent('and')
        affilFilter.add(parent, fil)
      })

      const filterParams = affilFilter.asQueryParams()
      const sortParams = affilSort.asQueryParams()

      this.affiliateList = await affiliates
        .getAffiliates({
          sorts: sortParams,
          filters: filterParams,
          pageSize: 5,
          page: 1,
        })
        .then(({ data }) => {
          return data.resultList
        })
        .catch((e) => {})

      this.immediate = true
    },
    async rebuildFilters(savedFilters) {
      const banned = ['column']
      const whiteList = ['value', 'id', 'filterType']
      const categoriesAndColumns = [].concat(this.categoryFilters, this.columns)
      const rebuiltFilters = []
      const isParent = (possibleParent) =>
        !possibleParent.parent || possibleParent.method
      const rebuild = async (source, destination) => {
        const sourcePaths = objectDataPaths(source)

        if (!destination.column) {
          destination = { column: destination }
        }
        Object.keys(source).forEach((key) => {
          if (!banned.includes(key)) {
            destination[key] = source[key]
          }
        })
        for (const sourcePath of sourcePaths) {
          const sourceValue = op(source, sourcePath)
          const sourcePathParts = sourcePath.split('/')
          const sourcePathProp = sourcePathParts.pop()
          if (whiteList.includes(sourcePathProp)) {
            const destinationLocation =
              sourcePathParts.length === 0
                ? destination
                : op(destination, sourcePathParts.join('/'))
            try {
              if (
                destinationLocation.recalculate &&
                sourcePathProp === 'value'
              ) {
                const calculationFunction = this.calculatedValues[
                  destinationLocation.recalculate
                ]
                if (typeof calculationFunction === 'function') {
                  destinationLocation[
                    sourcePathProp
                  ] = await calculationFunction()
                }
              } else {
                destinationLocation[sourcePathProp] = sourceValue
              }
            } catch (e) {
              console.log('Attempt to set property value failed.', e)
            }
          }
        }
        return destination
      }
      for (const savedFilter of savedFilters) {
        const savedFilterId = savedFilter?.column?._t_id

        if (isParent(savedFilter)) {
          // always add parents as-is
          rebuiltFilters.push(savedFilter)
        } else {
          const categoryOrColumn = categoriesAndColumns.find(
            (c) => c?._t_id === savedFilterId
          )
          if (categoryOrColumn) {
            const rebuiltCategoryOrColumn = await rebuild(
              savedFilter,
              categoryOrColumn
            )
            rebuiltFilters.push(rebuiltCategoryOrColumn)
          }
        }
      }
      this.filters = rebuiltFilters.filter(
        (rebuiltFilter) => !isParent(rebuiltFilter)
      )
      this.selectedCategory = rebuiltFilters
        .filter((rebuiltFilter) => rebuiltFilter?.column?.categoryFilter)
        .map((rebuiltFilter) => rebuiltFilter.column)

      const nextDefaultFilter = this.filters.find((f) => !f.hideOnFilterBar)

      this.showFilters = true
      this.filterUtil.set(rebuiltFilters)
      this.changePage(this.pagination)
      setTimeout(() => {
        if (nextDefaultFilter) {
          this.setFilter({}, nextDefaultFilter.column)
        }
      }, 0)
    },
    shareFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    removeFilter() {
      this.tableProps.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    fullColumnDefinition(column) {
      return this.tableProps.columns.find((c) => c._t_id === column._t_id)
    },
    getColumnComponent(col) {
      const column = this.tableProps.columns.find((c) => c._t_id === col._t_id)
      if (column && column.component) {
        return column.component
      }
    },
    initSort(column, preventDirty) {
      const sortProp = column.sortProp || column.prop
      this.currentSort.key = uuid.v4()
      if (this.currentSort) {
        if (this.currentSort.prop === sortProp) {
          const { direction } = this.currentSort
          this.currentSort.direction = direction === 'desc' ? 'asc' : 'desc'
          return this.sort(this.currentSort)
        }
      }
      this.currentSort = {
        key: uuid.v4(),
        id: column._t_id,
        prop: sortProp,
        direction: 'desc',
      }
      return this.sort(this.currentSort)
    },
    async getColumns() {
      const columnsOut = []

      // NOTE: These columns are referenced by referralsFilters.js for Referral Rejections Report
      // If you change a column that is used as a drill down filter, update in the other file as well
      columnsOut.push(
        {
          _t_id: '05c0efb7-0308-43c4-96b0-f542f7f6d887',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link-managed-to-parent',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'startswith',
          sortProp: 'managedId',
          filterProp: 'managedId',
        },
        {
          _t_id: 'cf9c54a1-0687-4403-a8e6-b0c1f3771254',
          prop: 'active',
          text: 'Active',
          computedText: (item) => (item ? 'Yes' : 'No'),
          sort: false,
          filter: true,
          detail: false,
          defaultHidden: false,
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '872ba896-8921-496a-9290-2cc2beda7ffe',
          prop: 'charterUpQuote',
          text: 'Marketplace',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '3adbf226-e02b-4e5f-aba3-25c8004a696c',
          prop: 'isAutoReferred',
          text: 'Auto Referred',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '40aa6fbb-475c-4517-98b9-ebe191590d9c',
          prop: 'isAutoRejected',
          text: 'Auto Rejected',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: false,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '34be80a0-512d-45fa-8b10-9bf77df2f981',
          prop: 'companyId',
          text: 'Company ID',
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'eq',
        },
        {
          _t_id: 'f4858d18-d472-482b-9be7-f642b9222b14',
          prop: 'marketName',
          text: 'Market',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
        },
        {
          _t_id: '1deedc55-24dd-4605-bd36-463951fb134e',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          customFilterTabDisplay: (val) => {
            if (val === 'accepted' || val === 'fully_accepted') {
              return 'Accepted'
            }
            if (val === 'rejected') {
              return 'Rejected'
            }
            if (val === 'offered' || val === 'fully_offered') {
              return 'Offered'
            }
            return ''
          },
          predefined: [
            {
              text: 'Accepted',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'accepted',
            },
            {
              text: 'Offered',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'offered',
            },
            {
              text: 'Rejected',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'rejected',
            },
          ],
        },
        {
          _t_id: '277e062b-0ab9-4d6d-ab18-71c4f77347eb',
          prop: 'cancellationClassificationLabel',
          text: 'Cancellation Type',
          sort: true,
          filter: true,
          computedText: (item) => item || 'None Selected',
          type: 'string',
          filterType: 'eq',
        },
        {
          _t_id: 'c1a4aa76-e5e7-4cc1-b040-a817067accfe',
          prop: 'pickupDate',
          text: 'Pickup Date',
          computedText: (item, row) =>
            DateTime.fromISO(item, {
              zone: row.firstPickupTimeZone,
            }).toFormat('MM/dd/yyyy t ZZZZ'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          forceOneLine: true,
        },
        {
          _t_id: '1a907a23-d124-455d-9ee1-0073d8786a20',
          prop: 'amount',
          text: 'Amount',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '568dd83a-de61-426d-9499-a32e22142af1',
          prop: 'bookedByName',
          text: 'Booked By',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          predefined: userPredefined,
        },
        {
          _t_id: '6ddd4f83-e6ae-4d98-b8f2-d7b85bfbda91',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: false,
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
        },
        {
          _t_id: '0eb828b2-0340-40f8-941d-5fa4e8bf6427',
          prop: 'referralAcceptanceExpiration',
          text: 'Referral Acceptance Expiration',
          computedText: (item) =>
            item
              ? DateTime.fromISO(item, {
                  zone:
                    this.currentUser?.timeZone ||
                    this.currentUser?.company?.address?.timeZone,
                }).toFormat('MM/dd/yyyy t ZZZZ')
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultSort: true,
        },
        {
          _t_id: '48c192d3-f006-4626-801a-d2a0ad48e354',
          prop: 'referralAcceptanceExpirationLabel',
          text: 'Referral Acceptance Expiration Label',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
        }
      )

      // Hidden columns

      columnsOut.push(
        {
          _t_id: '065e9273-328e-4aa1-b861-494e47adc290',
          prop: 'referredOnConvert',
          text: 'Referred On Convert',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
          detail: false,
          defaultHidden: false,
        },
        {
          _t_id: '0d9b1e42-038c-42fc-9311-85449d748e52',
          prop: 'parentReservation.reservationStatus',
          text: 'Reservation Status',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          detail: false,
          defaultHidden: false,
          computedText: (item, row) =>
            row.reservationStatus ? capitalize(row.reservationStatus) : '',
          predefined: [
            {
              text: 'Upcoming',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'upcoming',
            },
            {
              text: 'Started',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'started',
            },
            {
              text: 'Finished',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'finished',
            },
            {
              text: 'On Hold',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'hold',
            },
            {
              text: 'Cancelled',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 'cancelled',
            },
          ],
        },
        {
          _t_id: 'f474abb4-ec79-4d36-a45b-133c5e02c02a',
          prop: 'rejectionMethod',
          text: 'Rejection Method',
          sort: true,
          filter: true,
          type: 'number',
          computedText: (item) =>
            this.rejectionMethodList.find((method) => method.id === item)
              ?.label || '',
          filterType: 'eq',
          detail: false,
          defaultHidden: true,
        }
      )

      this.tableProps.columns = columnsOut
    },
    setFiltersFromStore() {
      this.filterCancelledReferrals =
        this.referralsTVFilterValues?.cancelledReferrals || false

      let companySelection = {
        companyId: this.referralsTVFilterValues.companyId,
        name: this.referralsTVFilterValues.companyName,
      }
      if (this.referralsTVFilterValues.companyId) {
        this.affiliateList = [companySelection]
      }
      this.companySelector = companySelection

      let marketSelection = {
        marketId: this.referralsTVFilterValues.marketId,
        marketName: this.marketList.find(
          (market) => market.id === this.referralsTVFilterValues.marketId
        )?.marketName,
      }
      this.marketSelector = marketSelection

      let classificationSelection = this.classificationList.find(
        (classification) =>
          classification.classificationId ===
          this.referralsTVFilterValues.cancellationTypeId
      )
      this.classificationSelector = classificationSelection

      if (this.referralsTVFilterValues.firstName) {
        this.agentName =
          this.referralsTVFilterValues.firstName +
          ' ' +
          this.referralsTVFilterValues.lastName
      }

      this.marketplaceSelection =
        this.referralsTVFilterValues.marketplaceSelection || 'all'
      this.marketplaceSelectionIndex = this.marketplaceSelectionOptions.findIndex(
        (option) => {
          return option.value === this.marketplaceSelection
        }
      )

      this.referralCreationMethodSelection =
        this.referralsTVFilterValues.referralCreationMethodSelection || 'all'
      this.referralCreationMethodSelectionIndex = this.referralCreationMethodSelectionOptions.findIndex(
        (option) => {
          return option.value === this.referralCreationMethodSelection
        }
      )

      this.startDate =
        this.referralsTVFilterValues.fromDate ||
        DateTime.utc().minus({ days: 30 }).toFormat('yyyy-MM-dd')
      this.endDate =
        this.referralsTVFilterValues.toDate ||
        DateTime.utc().minus({ days: 30 }).toFormat('yyyy-MM-dd')
      this.dateToFilterOn =
        this.referralsTVFilterValues.dateToFilterOn || 'pickup_date'
    },
    async getTable() {
      await this.filterUtil.set(this.currentFilters)

      const requestId = v4()
      this.requestIdQueue.push(requestId)
      const sorts = this.sorts.asQueryParams()
      const filters = this.filterUtil.asQueryParams()
      const params = {
        sorts,
        filters,
        pageSize: this.tableProps.perPage,
        page: this.tableProps.currentPage,
      }
      this.tableProps.selectedRows = []
      this.tableProps.loading = true
      const reservationData = await this.$store.dispatch(
        'referrals/referralsTV',
        params
      )
      if (this.requestIdQueue[this.requestIdQueue.length - 1] != requestId) {
        this.requestIdQueue = this.requestIdQueue.filter(
          (id) => id != requestId
        )
        return
      }
      this.requestIdQueue = this.requestIdQueue.filter((id) => id != requestId)
      const reservations = reservationData.data
      this.tableProps.perPage = this.pagination.rowsPerPage
      this.tableProps.currentPage = this.pagination.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
      if (reservationData) {
        this.tableProps.list.forEach((item) => {
          item.deleteMe = this.handleDelete.bind(this)
        })
      }
      EventBus.$emit('reservations-refreshed')
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.tableProps.currentPage = pagination.page
      this.tableProps.perPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.customerTotal) {
        return
      }
      const { paymentStatus, customerTotal, balance, dueDate } = row
      const isPaymentOverdue = DateTime.local() > dueDate

      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${paymentStatusHeaderMap[paymentStatus || 'not_paid']}</h6>
          <p>Total Owed: ${currencyFilter(customerTotal)}${
          paymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(balance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[paymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[paymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, customerTotal),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return ' error-background'
      }
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.reservationId !== targetRow.reservationId
      )
    },
    onMarketplaceSelection(index) {
      this.marketplaceSelection = this.marketplaceSelectionOptions[index].value
      this.refresh()
    },
    onReferralCreationMethodSelection(index) {
      this.referralCreationMethodSelection = this.referralCreationMethodSelectionOptions[
        index
      ].value
      this.refresh()
    },
    onPartnerTypeSelection() {
      this.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input.v-text-field--solo {
  .v-input__control {
    .v-input__slot {
      background-color: white !important;
    }
  }
}

.v-btn-toggle--selected {
  box-shadow: none;
}

.cr-input {
  label {
    display: block;
    margin-bottom: 4px;
  }

  .select {
    width: 100%;
    max-width: 100%;
    height: 42px;

    select {
      width: 100%;
      max-width: 100%;
      height: 42px;
      color: $gray-medium-light;
      background: $secondary;
      border: 2px solid $secondary;
      outline: none;
      box-shadow: none;

      &:hover {
        border-color: $gray-light;
      }

      &:focus,
      &:focus:hover {
        border-color: $primary;
      }
    }

    option {
      color: $black;
    }

    option:first-child {
      color: $gray-medium-light;
    }
  }
}

.radio-group {
  flex: none;
  margin: 0;
  padding: 0;

  .v-radio {
    margin: 0 18px;
  }
}

.progress-bar {
  position: absolute;
  left: 0;
  top: -16px;
}
</style>
