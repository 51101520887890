<template>
  <div class="contact-detail">
    <v-card-title primary-title>
      <v-layout>
        <v-flex xs4>
          <b class="pr-1">Driver:</b>
          <span class="headline">
            {{ driver.firstName }}
            {{ driver.lastName }}
          </span>
        </v-flex>
        <v-flex xs5 offset-xs3>
          <span class="contact-row">
            <b>Phone:</b>
            <a id="driver-detail-href-phone" @click="flexCall">
              {{ phoneFormatFilter(driver.phoneNumber) }}
            </a>
            <br />
          </span>
          <span class="contact-row">
            &nbsp;&nbsp;
            <b>Email:</b>
            <a id="driver-href-email-to" :href="`mailto:${driver.email}`">
              {{ driver.email }}
            </a>
            <br />
          </span>
        </v-flex>
      </v-layout>
    </v-card-title>
  </div>
</template>

<script>
import drivers from '@/services/drivers'
import { phoneFormatFilter } from '@/utils/phone'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      phoneFormatFilter,
      driver: {},
    }
  },
  async mounted() {
    const response = await drivers.getDriver(this.row.actionProps)

    this.driver = response.data.driver
  },
  methods: {
    flexCall() {
      const params = {
        customer: this.driver,
      }
      this.$store.dispatch('twilio/callNumber', params)
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-detail {
  padding: 20px;
  margin-top: 0;
  background-color: $blue-pale;

  .contact-row {
    b {
      padding-right: 15px;
    }

    a {
      text-decoration: none;
    }
  }
}
</style>
