
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'

import op from 'simple-object-path'
import { deepClone } from '@/utils/deepClone'
import { currencyFilter } from '@/utils/currency'
import { ProviderConflict } from '@/utils/enum'
import { phoneFormatFilter } from '@/utils/phone'
import * as logger from '@/utils/logger'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { textLike } from '@/utils/predefined'
import MultiMarket from './ModifiedMultimarkets.vue'
import ReservationQuickReferTakeHomeAmount from './ReservationQuickReferTakeHomeAmount.vue'
import ReservationQuickReferRateIcon from './ReservationQuickReferRateIcon.vue'
import CharterUPOperatingStatusTypeFilter from './CharterUPOperatingStatusTypeFilter.vue'
import DOTCarrierOperationFilter from './DOTCarrierOperationFilter.vue'
import PartnerTypeFilter from './PartnerTypeFilter.vue'
import UnavailabilityOverrideSidebar from './UnavailabilityOverrideSidebar.vue'
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import affiliates from '@/services/affiliates'
import availability from '@/services/availability'
import { DateTime } from 'luxon'
import { Reservation, UserProfile, Role, Vehicle, Affiliate, ApiResult, Market } from '@/models/dto'
import { SplitFeatureFlag } from '@/utils/enum'
import { ReferredProvider } from '@/models/dto'

const PARTNER_TAKE_RATE = 0.1
const NON_PARTNER_TAKE_RATE = 0.2
const PARTNER_TYPES = ['Platinum', 'Gold', 'Silver', 'Bronze']
const NON_PARTNER_TYPE_LABEL = 'Non-Partner'
const DOT_OUT_OF_SERVICE_TYPE = 'Out of Service'

@Component({
  components: {
    MultiMarket,
    ReservationQuickReferTakeHomeAmount,
    ReservationQuickReferRateIcon,
    CharterUPOperatingStatusTypeFilter,
    DOTCarrierOperationFilter,
    PartnerTypeFilter,
    UnavailabilityOverrideSidebar,
  },
})
export default class ReservationQuickRefer extends Vue {
  @Prop({ type: Object, required: true }) readonly reservation: Reservation

  filters = filter()
  sortUp: boolean = true
  requiredDrivers: number = 0
  requiredPassengers: number = 0
  requiredVehicles: Vehicle[] = []
  filteredAffiliates: Affiliate[] = []
  affiliates: Affiliate[] = []
  affiliatePage: Affiliate[] = []
  filteredAffiliatePage: Affiliate[] = []
  isFilteringAffiliates: boolean = false
  offeredAffiliatePage: Affiliate[] = []
  currentPage: number = 1
  perPage: number = 5
  loading: boolean = false
  filteredLoading: boolean = false
  offeredLoading: boolean = false
  searchText: string = null
  offeredMap: Record<string, boolean> = {}
  markets: Market[] = []
  partnerTypes: number[] = []
  carrierOperationTypes: number[] = []
  charterUPOperatingStatusTypes: number[] = []
  disableAuthForPassengerFilter: boolean = false
  disableAuthForHireFilter: boolean = false
  debounce: any = null
  immediate: boolean = true
  offeredDebounce: any = null
  offeredImmediate: boolean = true
  filterByCategory: string = 'name'
  totalFilteredAffiliatePages: number = 100
  totalPages: number = 100
  preBookingLink: {
    reservationId: number,
    prebooking: {
      reservationId: number,
      companyId: number,
      requiredVehicles: Vehicle[],
      amount: number
    }
  } = null
  companyConflict: Record<string, string> = {}
  isUnavailabilityOverrideSidebarOpen: boolean = false
  unavailabilityOverrideSidebarCompanyId: number = null
  showNotAuthorizedOperators: boolean = false
  isNewGarageEntitiesEnabled: boolean = false

  currencyFilter = currencyFilter
  phoneFormatFilter = phoneFormatFilter
  op = op

  get isReferralRejectionReasonV2Enabled(): boolean {
    return this.$store.getters['featureToggles/isReferralRejectionReasonV2Enabled']
  }

  get isAvailabilityV3Enabled(): boolean {
    return this.$store.getters['featureToggles/isAvailabilityV3Enabled']
  }

  get currentUserProfile() {
    return this.$store.getters['auth/currentUserProfile'] || {}
  }

  get canOverrideReferralPrice(): Role {
    return (
      this.currentUserProfile?.roles?.find(
        (r) => r.roleName === 'can_override_referral_price'
      ) || null
    )
  }

  get isOpsAgent(): boolean {
    return !!this.currentUserProfile?.roles?.find(
      (r) => r.roleName === 'is_ops_agent'
    )
  }

  get canControlPreBookings(): boolean {
    return !!this.currentUserProfile?.roles?.find(
      (r) => r.roleName === 'has_ess_control'
    )
  }

  get offeredCompanyIds(): number[] {
    return this.reservation.referredTo.map((rt) => rt.companyId)
  }

  get allAffiliatesPage(): Affiliate[] {
    if (this.isFilteringAffiliates) {
      return this.offeredAffiliatePage.concat(this.filteredAffiliatePage)
    }
    return this.offeredAffiliatePage.concat(this.affiliatePage)
  }

  get endDatetime(): string {
    const stops = [...this.reservation?.stops]?.reverse()
    return stops?.find((s) => s.dropoffDatetime != null)?.dropoffDatetime
  }

  get hasReferralEditPermission(): boolean {
    return this.$store.getters['auth/hasPermission']('canEditReferrals')
  }

  @Watch('reservation', { immediate: true })
  async onReservationChange(): Promise<void> {
    const firstStopAddress = this.reservation?.stops?.[0]?.address;
    this.requiredDrivers = this.reservation?.driverCount;
    this.requiredPassengers = this.reservation?.passengerCount;
    this.requiredVehicles = this.reservation?.requiredVehicles;
    if (firstStopAddress) {
      await this.filterOfferedAffiliates();
      await this.getAllAffiliates();
    }
  }

  @Watch('affiliatePage', { immediate: true })
  onAffiliatePageChange(newAffiliatesPage: Affiliate[]): void {
    if (!newAffiliatesPage || !this.reservation?.requiredVehicles) {
      return;
    }
    newAffiliatesPage.map((affiliate) => {
      affiliate.model = [];
      this.reservation.requiredVehicles.map((requiredVehicle) => {
        const modelObject = {
          quantity: requiredVehicle.quantity,
          vehicleTypeId: requiredVehicle.vehicleType.id,
        };
        affiliate.model.push(modelObject);
      });
    });
  }

  @Watch('filteredAffiliatePage', { immediate: false })
  onFilteredAffiliatePageChange(newAffiliatesPage: Affiliate[]): void {
    if (!newAffiliatesPage || !this.reservation?.requiredVehicles) {
      this.isFilteringAffiliates = false;
      return;
    }
    if (newAffiliatesPage.length > 0) {
      this.isFilteringAffiliates = true;
      newAffiliatesPage.map((affiliate) => {
        affiliate.model = [];
        this.reservation.requiredVehicles.map((requiredVehicle) => {
          const modelObject = {
            quantity: requiredVehicle.quantity,
            vehicleTypeId: requiredVehicle.vehicleType.id,
          };
          affiliate.model.push(modelObject);
        });
      });
    }
  }

  @Watch('offeredAffiliatePage', { immediate: true })
  onOfferedAffiliatePageChange(newAffiliatesPage: Affiliate[]): void {
    if (!newAffiliatesPage || !this.reservation?.requiredVehicles) {
      return;
    }
    newAffiliatesPage.map((affiliate) => {
      affiliate.model = [];
      const { requiredVehicles: affiliateRequiredVehicles } = this.getAffiliateReferral(affiliate);
      this.reservation.requiredVehicles.map((requiredVehicle) => {
        const modelObject = {
          quantity: 0,
          vehicleTypeId: requiredVehicle.vehicleType.id,
        };

        const foundVehicle = affiliateRequiredVehicles.find(
          (affiliateRequiredVehicle) =>
            affiliateRequiredVehicle.vehicleType.id === requiredVehicle.vehicleType.id
        );

        if (foundVehicle) {
          modelObject.quantity = foundVehicle.quantity;
        }

        affiliate.model.push(modelObject);
      });
    });
  }

  @Watch('$store.state.split.isReady', { immediate: true })
  async onSplitReady(isReady: boolean): Promise<void> {
    if (isReady) {
      this.showNotAuthorizedOperators = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.ShowNotAuthorizedOperators)
      this.isNewGarageEntitiesEnabled = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.NewGarageEntities)
    }
  }

  @Watch('showNotAuthorizedOperators')
  onShowNotAuthorizedOperatorsUpdate(newValue: boolean): void {
    if (newValue) {
      this.disableAuthForHireFilter = true
      this.disableAuthForPassengerFilter = true
    }
  }

  mounted(): void {
    EventBus.$on('link-prebooking-row', (reservationId, prebooking) => {
      this.preBookingLink = {
        reservationId: reservationId,
        prebooking: prebooking,
      }
    })
    EventBus.$on('refresh-detail', () => {
      this.$emit('refresh-query-request')
    })

    EventBus.$on('pricing-change', (takeRate, companyId, takeHomeAmount) => {
        const affiliate = this.getAffiliateForId(companyId)

        if (!affiliate) {
          return
        }

        affiliate.takeRate = takeRate
        this.changeTakeHomeAmount(affiliate, takeHomeAmount)
      })
  }

  partnerTypeLabelClass(label: string): string {
    switch (label) {
      case 'Gold Reseller Brand':
      case 'Silver Reseller Brand':
      case 'Bronze Reseller Brand':
      case 'Platinum':
      case 'Silver':
      case 'Gold':
      case 'Bronze':
        return 'background-blue-new text-white'
      case 'Banned':
        return 'background-error-new text-white'
      case 'Non-Partner':
      case 'Internal Use':
      default:
        return 'background-gray-light text-gray-dark'
    }
  }

  charterUPOperatingStatusClass(key: string): string {
    switch (key) {
      case 'available':
      case 'enterprise_only':
        return 'background-gray-light text-gray-dark'
      case 'do_not_contact':
      case 'with_management_approval':
      default:
        return 'background-gray-medium text-gray-dark'
    }
  }

  dotOutOfService(affiliate: Affiliate): boolean {
    return affiliate.dotStatusType?.label === DOT_OUT_OF_SERVICE_TYPE
  }

  dotOutOfServiceDate(affiliate: Affiliate): string {
    return affiliate.dotOutOfServiceDate ? DateTime.fromISO(affiliate.dotOutOfServiceDate).toLocaleString() : ''
  }

  dotAuthorityStatus(affiliate: Affiliate): string {
    if (affiliate.dotAuthorized === null) {
      return ''
    }
    if (affiliate.dotAuthorized) {
      return 'Authorized'
    }
    return 'Not Authorized'
  }

  dotAuthorizedForPassengerLabel(affiliate: Affiliate): string {
    if (affiliate.dotAuthorizedForPassenger === null) {
      return ''
    }
    if (affiliate.dotAuthorizedForPassenger) {
      return 'Yes'
    }
    return 'No'
  }

  dotAuthorizedForHireLabel(affiliate: Affiliate): string {
    if (affiliate.dotAuthorizedForHire === null) {
      return ''
    }
    if (affiliate.dotAuthorizedForHire) {
      return 'Yes'
    }
    return 'No'
  }

  dotCarrierOperation(affiliate: Affiliate): string {
    if (affiliate.dotInterstateAllowed === null) {
      return ''
    }
    if (affiliate.dotInterstateAllowed) {
      return 'Interstate'
    }
    return 'Intrastate Only'
  }

  dotUpdatedLabel(affiliate: Affiliate) : string {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : ''
    return affiliate.dotUpdatedAt ? this.isoToString(affiliate.dotUpdatedAt, userTimeZone) : ''
  }

  isoToString(date: string, timeZone: string): string {
    if (timeZone) {
      const datetime = DateTime.fromISO(date, { zone: timeZone })
      return datetime.toFormat('M/dd/yyyy t ZZZZ')
    }
    const datetime = DateTime.fromISO(date)
    return datetime.toFormat('M/dd/yyyy t')
  }

  setMarketFilter(e): void {
    this.markets = e
    this.immediate = true
    this.filterAffiliates()
  }

  setPartnerTypeFilter(e): void {
    this.partnerTypes = e
    this.immediate = true
    this.filterAffiliates()
  }

  updateDotAuthFilter(): void {
    this.immediate = true
    this.filterAffiliates(1, true)
  }

  setCarrierOperationFilter(e): void {
    this.carrierOperationTypes = e
    this.immediate = true
    this.filterAffiliates()
  }

  setCharterUPOperatingStatusFilter(e): void {
    this.charterUPOperatingStatusTypes = e
    this.immediate = true
    this.filterAffiliates()
  }

  setSort(): void {
    this.sortUp = !this.sortUp
    this.immediate = true
    this.filterAffiliates()
  }

  searchInput(arg: number): void {
    this.immediate = false
    this.filterAffiliates()
  }

  shouldShowEditReferralButton(affiliate: Affiliate): boolean {
    return (
      this.referralAccepted(affiliate) &&
      this.hasReferralEditPermission
    )
  }

  referralAccepted(affiliate: Affiliate): boolean {
    const foundAffiliate = this.reservation.referredTo.find(
      (rt) => rt.companyId === affiliate.companyId
    )
    return foundAffiliate?.referralStatus === 'accepted'
  }

  availabilityTooltipText(status: string): string {
    let res = 'This provider has a conflict for this pickup date.'
    if (status === ProviderConflict.Partial) {
      res +=
        ' This provider has less vehicles available than the trip requires.'
    } else if (status === ProviderConflict.Complete) {
      res += ' There are no available vehicles.'
    }
    return res
  }

  openEditReferralSidebar(companyId: number): void {
    const referral = this.reservation.referredTo.find(
      (rt) => rt.companyId === companyId
    )
    const component = () => import('./ReferralEditSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      component,
      data: {
        referral,
        reservationId: this.reservation.reservationId,
        driverCount: this.reservation.driverCount,
        passengerCount: this.reservation.passengerCount,
        requiredVehicles: this.reservation.requiredVehicles,
        title: `Edit Referral`,
      },
    })
  }

  async getAllAffiliates(arg?: number): Promise<void> {
    this.loading = true
    const page = arg || 1
    this.filters.clear()

    let sorts = sort()
    if (this.sortUp) {
      sorts.add({
        prop: 'referralCount',
        direction: 'desc',
      })
    } else {
      sorts.add({
        prop: 'referralCount',
        direction: 'asc',
      })
    }

    this.affiliatePage = await affiliates
      .getAffiliates({
        sorts: sorts.asQueryParams(),
        filters: this.filters.asQueryParams(),
        pageSize: 5,
        page,
        filterAuthForHire: !this.disableAuthForHireFilter,
        filterAuthForPassenger: !this.disableAuthForPassengerFilter,
      })
      .then(({ data }) => {
        this.totalPages = Math.ceil(data.count / 5)
        const res = data.resultList
          .filter(
            (affiliate) =>
              !this.offeredCompanyIds.find(
                (oci) => oci === affiliate.companyId
              )
          )
          .sort((a, b) => {
            const first = this.isAffiliateReferred(a) ? 1 : -1
            const second = this.isAffiliateReferred(b) ? 1 : -1

            return second - first
          })
        return res
      })
      .then((res) => {
        res.forEach((affil) => {
          if (!affil.referralPassengerCount) {
            affil.referralPassengerCount = this.requiredPassengers
          }
          if (!affil.offerAmount) {
            affil.offerAmount = null
            affil.takeHomeAmount = null
          }
        })

        return res
      })

    if (this.isAvailabilityV3Enabled) {
      try {
        await this.loadConflicts(this.affiliatePage)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
    this.loading = false
  }

  affiliateGarages(affiliate): any[] {
    if (this.isNewGarageEntitiesEnabled) {
      return affiliate.garagesV2
    }
    return affiliate.garages
  }

  async filterAffiliates(arg?: number, force?: boolean): Promise<void> {
    if (this.markets.length === 0 && this.partnerTypes.length === 0 && this.carrierOperationTypes.length === 0 && this.charterUPOperatingStatusTypes.length === 0 && !this.searchText && !force) {
      this.filteredAffiliatePage = []
      this.isFilteringAffiliates = false
      this.getAllAffiliates()
      return
    }
    this.isFilteringAffiliates = true

    if (this.debounce) {
      clearTimeout(this.debounce)
    }

    if (!this.immediate) {
      this.debounce = setTimeout(() => {
        this.immediate = true
        this.filterAffiliates(arg)
      }, 1000)
      return
    }

    this.filteredLoading = true
    this.immediate = false
    const page = arg || 1
    this.filters.clear()

    if (this.markets.length > 0) {
      const marketFilter = {
        column: {
          _t_id: 'marketins',
          prop: 'nearestMarketId',
          filterType: 'eq',
        },
        value: this.markets.join(' '),
      }

      this.filters.remove(marketFilter)
      this.filters.add(this.filters.createParent('and'), marketFilter)
    }

    if (this.partnerTypes.length > 0) {
      const partnerTypeFilter = {
        column: {
          _t_id: 'partnertypeins',
          prop: 'partnerTypeId',
          filterType: 'eq',
          method: 'or',
        },
        value: this.partnerTypes.join(' ',)
      }

      this.filters.remove(partnerTypeFilter)
      this.filters.add(this.filters.createParent('and'), partnerTypeFilter)
    }

    if (this.carrierOperationTypes.length > 0) {
      const carrierOperationFilter = {
        column: {
          _t_id: 'carrieroperationins',
          prop: 'dotInterstateAllowed',
          filterType: 'eq',
        },
        value: this.carrierOperationTypes.join(' ',)
      }

      this.filters.remove(carrierOperationFilter)
      this.filters.add(this.filters.createParent('and'), carrierOperationFilter)
    }

    if (this.charterUPOperatingStatusTypes.length > 0) {
      const charterUPOperatingStatusFilter = {
        column: {
          _t_id: 'charterupoperatingstatusins',
          prop: 'charterUPOperatingStatusId',
          filterType: 'eq',
        },
        value: this.charterUPOperatingStatusTypes.join(' ',)
      }

      this.filters.remove(charterUPOperatingStatusFilter)
      this.filters.add(this.filters.createParent('and'), charterUPOperatingStatusFilter)
    }

    let selectedPredefined = deepClone(textLike[0])
    selectedPredefined.controls[0].value = this.searchText
    const textFilter = {
      column: {
        _t_id: 'text_search_refer',
        prop: 'name',
        filterType: 'contains',
        method: 'and',
      },
      selectedPredefined,
      value: this.searchText,
    }
    this.filters.remove(textFilter)

    if (this.searchText) {
      this.filters.add(this.filters.createParent('and'), textFilter)
    }

    let sorts = sort()
    if (this.sortUp) {
      sorts.add({
        prop: 'referralCount',
        direction: 'desc',
      })
    } else {
      sorts.add({
        prop: 'referralCount',
        direction: 'asc',
      })
    }

    this.filteredAffiliatePage = await affiliates
      .getAffiliates({
        sorts: sorts.asQueryParams(),
        filters: this.filters.asQueryParams(),
        pageSize: 5,
        page,
        filterAuthForHire: !this.disableAuthForHireFilter,
        filterAuthForPassenger: !this.disableAuthForPassengerFilter,
      })
      .then(({ data }) => {
        this.totalFilteredAffiliatePages = Math.ceil(data.count / 5)
        const res = data.resultList
          .filter(
            (affiliate) =>
              !this.offeredCompanyIds.find(
                (oci) => oci === affiliate.companyId
              )
          )
          .sort((a, b) => {
            const first = this.isAffiliateReferred(a) ? 1 : -1
            const second = this.isAffiliateReferred(b) ? 1 : -1

            return second - first
          })

        return res
      })
      .then((res) => {
        res.forEach((affil) => {
          if (!affil.referralPassengerCount) {
            affil.referralPassengerCount = this.requiredPassengers
          }
          if (!affil.offerAmount) {
            affil.offerAmount = null
            affil.takeHomeAmount = null
          }
        })

        return res
      })
      .catch((e) => {
        this.filteredLoading = false
        return []
      })

    if (this.isAvailabilityV3Enabled) {
      try {
        await this.loadConflicts(this.filteredAffiliatePage)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }

    this.filteredLoading = false
    this.immediate = true
  }

  async filterOfferedAffiliates(): Promise<void> {
    if (!this.offeredCompanyIds.length) {
      return
    }
    if (this.offeredDebounce) {
      clearTimeout(this.offeredDebounce)
    }

    if (!this.offeredImmediate) {
      this.offeredDebounce = setTimeout(() => {
        this.offeredImmediate = true
        this.filterOfferedAffiliates()
      }, 500)
      return
    }

    this.offeredLoading = true
    this.offeredImmediate = false
    const page = 1
    this.filters.clear()

    const offeredFilter = {
      column: {
        _t_id: 'offered_filter',
        prop: 'companyId',
        filterType: 'in',
        method: 'and',
      },
      value: this.offeredCompanyIds,
    }
    this.filters.add(this.filters.createParent('and'), offeredFilter)

    this.offeredAffiliatePage = await affiliates
      .getAffiliates({
        filters: this.filters.asQueryParams(),
        pageSize: 5,
        page,
      })
      .then(({ data }) => {
        this.offeredLoading = false
        const res = data.resultList.sort((a, b) => {
          const first = this.isAffiliateReferred(a) ? 1 : -1
          const second = this.isAffiliateReferred(b) ? 1 : -1

          return second - first
        })
        return res
      })
      .then((res) => {
        res.forEach((affil) => {
          if (!affil.referralPassengerCount) {
            affil.referralPassengerCount = this.requiredPassengers
          }
          if (!affil.offerAmount) {
            affil.offerAmount = null
            affil.takeHomeAmount = null
          }
        })
        return res
      })
      .catch((e) => {
        this.offeredLoading = false
        return []
      })

    if (this.isAvailabilityV3Enabled) {
      await this.loadConflicts(this.offeredAffiliatePage)
    }

    this.offeredImmediate = true
  }

  async loadConflicts(page: Affiliate[]) {
    await Promise.all(
      page.map(async (affiliate) => {
        const companyId = affiliate.companyId
        const params = {
          payload: {
            startDatetime: DateTime.fromISO(this.reservation?.startDate),
            endDatetime: DateTime.fromISO(
              this.endDatetime || this.reservation?.startDate
            ).endOf('day'),
            vehicleIds: affiliate.vehicles?.map((v) => v.vehicleId),
            vehicleTypeIds: this.reservation?.requiredVehicles.map(
              (v) => v.vehicleType.id
            ),
            companyId,
          },
        }
        const promises = [
          availability.getCompanyBlocks(params),
          availability.getVehicleTypeBlocks(params),
          availability.getVehicleBlocks(params),
        ]
        const responses = await Promise.all(promises)
        const companyBlocks = responses?.[0]?.data?.companyAvailabilityBlocks
        const vehicleTypeBlocks =
          responses?.[1]?.data?.vehicleTypeAvailabilityBlocks
        const vehicleBlocks = responses?.[2]?.data?.vehicleAvailabilityBlocks

        if (companyBlocks?.length > 0) {
          this.companyConflict[companyId] = ProviderConflict.Complete
        } else {
          const dispatchParams = {
            payload: {
              from: DateTime.fromISO(this.reservation?.startDate),
              to: DateTime.fromISO(this.endDatetime),
              companyId,
            },
          }
          const dispatchResult = await this.$store.dispatch(
            'dispatch/getDispatchDataAsBroker',
            dispatchParams
          )
          let assignedOrOfferedReservations
          let requiredVehicles
          if (this.isAffiliateReferred(affiliate)) {
            const referral = this.getAffiliateReferral(affiliate)
            assignedOrOfferedReservations = dispatchResult?.data?.reservations?.filter(
              (r) => r.reservationId != referral.reservationId
            )
            requiredVehicles = referral?.requiredVehicles
          } else {
            assignedOrOfferedReservations = dispatchResult?.data?.reservations
            requiredVehicles = this.reservation?.requiredVehicles
          }

          const vehicleConflictCounts = {
            none: 0,
            partial: 0,
            complete: 0,
          }
          for (const requiredVehicle of requiredVehicles) {
            if (
              vehicleTypeBlocks.some(
                (b) => b.vehicleTypeId === requiredVehicle.vehicleType.id
              )
            ) {
              vehicleConflictCounts[ProviderConflict.Complete]++
              continue
            }
            let count = affiliate.vehicles?.filter(
              (v) => v.vehicleTypeId === requiredVehicle.vehicleType.id
            ).length
            const blockedOutVehicleCount = vehicleBlocks?.filter(
              (vb) => vb.vehicleTypeId === requiredVehicle.vehicleType.id
            ).length
            count -= blockedOutVehicleCount
            for (const assignedOrOfferedReservation of assignedOrOfferedReservations) {
              for (const assignedOrOfferedVehicle of assignedOrOfferedReservation.requiredVehicles) {
                if (
                  assignedOrOfferedVehicle.vehicleType.id ===
                  requiredVehicle.vehicleType.id
                ) {
                  count -= assignedOrOfferedVehicle.quantity
                }
              }
            }
            if (count <= 0) {
              vehicleConflictCounts[ProviderConflict.Complete]++
            } else if (count < requiredVehicle.quantity) {
              vehicleConflictCounts[ProviderConflict.Partial]++
            } else {
              vehicleConflictCounts[ProviderConflict.None]++
            }
          }

          if (
            vehicleConflictCounts[ProviderConflict.Partial] > 0 ||
            (vehicleConflictCounts[ProviderConflict.None] > 0 &&
              vehicleConflictCounts[ProviderConflict.Complete] > 0)
          ) {
            this.companyConflict[companyId] = ProviderConflict.Partial
          } else if (vehicleConflictCounts[ProviderConflict.Complete] > 0) {
            this.companyConflict[companyId] = ProviderConflict.Complete
          } else {
            this.companyConflict[companyId] = ProviderConflict.None
          }
        }
      })
    )
  }

  changePage(currentPage: number) {
    this.currentPage = currentPage
    if (this.isFilteringAffiliates) {
      this.filterAffiliates(currentPage)
    } else {
      this.getAllAffiliates(currentPage)
    }
  }

  isAffiliateReferred(affiliate: Affiliate): boolean {
    const referred = this.getAffiliateReferral(affiliate)
    return !!referred
  }

  async unassignReferral(affiliate: Affiliate): Promise<void> {
    const { referredTo: referrals = [] } = this.reservation
    const referral = referrals.find(
      (r) => r?.companyId === affiliate.companyId
    )
    if (referral) {
      let component
      if (
        this.isReferralRejectionReasonV2Enabled &&
        !this.referralAccepted(affiliate)
      ) {
        component = () => import('./ReferralRejectSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          component,
          data: {
            reservation: this.reservation,
            referral,
            title: `Reject Referral`,
          },
        })
      } else {
        component = () => import('@/components/UnassignReferralSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            reservation: this.reservation,
            referral: referral,
            isReject: !this.referralAccepted(affiliate),
            title: this.sidebarTitle(this.referralAccepted(affiliate)),
          },
          component,
        })
      }
    }
  }

  toggleSidebar(companyId: number) {
    this.isUnavailabilityOverrideSidebarOpen = true
    this.unavailabilityOverrideSidebarCompanyId = companyId
  }

  getAffiliateForId(companyId: number): Affiliate {
    return this.allAffiliatesPage.find((a) => a.companyId === companyId)
  }

  hasConflict(companyId: number): boolean {
    return (
      this.companyConflict[companyId] === ProviderConflict.Complete ||
      this.companyConflict[companyId] === ProviderConflict.Partial
    )
  }

  hasPartialConflict(companyId: number): boolean {
    return this.companyConflict[companyId] === ProviderConflict.Partial
  }

  hasInvalidVehicleQuantity(affiliate) {
      return affiliate.model.some(item => {
        const quantity = item.quantity;
        return quantity === '' || parseInt(quantity) < 0;
      });
  }

  async offerReferral(affiliate: Affiliate): Promise<void> {
    if (this.hasInvalidVehicleQuantity(affiliate)) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Invalid number of vehicles'
      })
      return
    }
    if (
      this.reservation.maxReferralAmount != null &&
      affiliate.offerAmount > this.reservation.maxReferralAmount &&
      !this.canOverrideReferralPrice &&
      this.isOpsAgent
    ) {
      this.$store.getters['app/showAlert']({
        type: 'error',
        message:
          'This referral is above the max referral amount ' +
          this.reservation.maxReferralAmount +
          '. Please reduce to send to the operator',
      })
      return
    }
    const {
      vehicles = [],
      requestedDriverCount,
      offerAmount = 0,
      takeHomeAmount = 0,
      takeRate = 0
    } = affiliate
    if (!affiliate.offerAmount) {
      return
    }
    delete affiliate.vehicles
    delete affiliate.requestedDriverCount
    delete affiliate.offerAmount
    delete affiliate.takeHomeRate
    delete affiliate.takeRate
    if (affiliate.id === null) {
      delete affiliate.id
    }

    const affiliateCompanyId = affiliate?.companyId

    const reservationId = this.reservation.reservationId
    if (
      typeof this.offeredMap[`${affiliateCompanyId}-${reservationId}`] !==
      'undefined'
    ) {
      return logger.warn(
        `Reservation ID ${reservationId} is already offered to Affiliate ID ${affiliateCompanyId}`
      )
    }
    if (affiliateCompanyId && reservationId) {
      this.offeredMap[`${affiliateCompanyId}-${reservationId}`] = true
    }

    const newRequiredVehicle = (vehicleType, quantity = 0) => {
      return {
        quantity,
        vehicleTypeId: vehicleType.id,
      }
    }
    const newRequiredVehicles = []

    affiliate.model.forEach((modelObject) => {
      const matchingRequiredVehicle = this.requiredVehicles.find(
        (vehicle) => vehicle.vehicleType.id === modelObject.vehicleTypeId
      )
      const { vehicleType } = matchingRequiredVehicle
      newRequiredVehicles.push(
        newRequiredVehicle(vehicleType, Number(modelObject.quantity))
      )
    })

    const offerParams = {
      amount: offerAmount,
      takeRate: takeRate,
      companyId: affiliateCompanyId,
      requiredDrivers: requestedDriverCount || this.requiredDrivers,
      reservationId,
      referralVehicles: newRequiredVehicles,
      referralPassengerCount: affiliate.referralPassengerCount,
      isAvailabilityOverride:
        this.isAvailabilityV3Enabled && this.hasConflict(affiliateCompanyId),
    }

    let response: AxiosResponse<ApiResult> = null
    if (this.showPreBookingRow(this.reservation, affiliate)) {
      response = await this.$store.dispatch(
        'reservations/linkPreBookingAndOfferReferral',
        {
          ...offerParams,
          preBookingId: this.preBookingLink.prebooking.reservationId,
          takeHomeAmount: takeHomeAmount,
        }
      )
    } else {
      response = await this.$store.dispatch(
        'reservations/offerReferral',
        offerParams
      )
    }

    if (response?.data?.successful) {
      await this.$store.dispatch('reservations/setManualReferralNeeded', {
        reservationIds: [reservationId],
        needsManualReferral: true,
      })
    }

    this.$emit('refresh-query-request')
  }

  changeDriverCount(affiliate: Affiliate, driverCount: number): void {
    affiliate.requestedDriverCount = driverCount
  }

  getAffiliateReferral(affiliate: Affiliate): ReferredProvider {
    const { referredTo } = this.reservation
    const nonRejectedReferrals = referredTo.filter(
      (referral) => referral?.referralStatus !== 'rejected'
    )

    const affiliateCompanyId = affiliate?.companyId
    const referred = nonRejectedReferrals.find(
      (nonRejectedReferral) =>
        nonRejectedReferral?.companyId === affiliateCompanyId
    )
    return referred
  }

  getDriverCount(affiliate: Affiliate): number {
    const referred = this.getAffiliateReferral(affiliate)
    if (referred) {
      return referred.driverCount
    }
    return 0
  }

  getPassengerCount(affiliate: Affiliate): number {
    const referred = this.getAffiliateReferral(affiliate)
    if (referred) {
      return referred.referralPassengerCount
    }
    return 0
  }

  changePassengerCount(affiliate: Affiliate, passengerCount: number): void {
    affiliate.referralPassengerCount = passengerCount
  }

  getTakeHomeAmount(affiliate: Affiliate): number {
    const referred = this.getAffiliateReferral(affiliate)
    if (referred) {
      if (referred.referralStatus === 'reoffered') {
        return referred.originalAmount
      } else {
        return referred.referralAmount
      }
    }
    return 0
  }

  getTakeHomeRate(affiliate: Affiliate): number {
    const isPartner = PARTNER_TYPES.includes(affiliate.partnerTypeLabel)
    const isNonPartner = affiliate.partnerTypeLabel === NON_PARTNER_TYPE_LABEL
    let takeHomeRate
    if (isPartner) {
      const affiliateTakeRate = !!affiliate.takeRate ? affiliate.takeRate : (PARTNER_TAKE_RATE * 100)
      const effectiveTakeRate = affiliateTakeRate * .01
      affiliate.takeRate = effectiveTakeRate * 100
      takeHomeRate = 1 - effectiveTakeRate
    } else if (isNonPartner) {
      affiliate.takeRate = NON_PARTNER_TAKE_RATE * 100
      takeHomeRate = 1 - NON_PARTNER_TAKE_RATE
    } else {
      affiliate.takeRate = 0
      takeHomeRate = 1
    }
    return takeHomeRate
  }

  changeTakeHomeAmount(affiliate: Affiliate, takeHomeAmount: number): void {
    affiliate.takeHomeAmount = takeHomeAmount
    affiliate.offerAmount = affiliate.takeHomeAmount / this.getTakeHomeRate(affiliate)
  }

  openPreBookingSidebar(companyId: number, reservationId: number, reservationManagedId: number): void {
    const component = () => import('./PreBookingSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: {
        companyId,
        reservationId,
        title: `Link Pre-Booking to ${reservationManagedId}`,
      },
      component,
    })
  }

  showPreBookingRow(reservation: Reservation, affiliate: Affiliate): boolean {
    return (
      !reservation.isPreBooking &&
      reservation.reservationId === this.preBookingLink?.reservationId &&
      affiliate.companyId === this.preBookingLink?.prebooking?.companyId
    )
  }

  sidebarTitle(isAccepted: boolean): string {
    if (!isAccepted) {
      return 'Reject Referral'
    }
    if (this.reservation?.isPreBooking) {
      return 'Unassign Referral'
    }
    return 'Operator Cancel Referral'
  }
}
