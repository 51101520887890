<template>
  <v-container class="form-cont">
    <v-layout class="layout-panel">
      <div style="display: flex; flex-direction: column; width: 45%">
        <CRInput
          :id="`${id}-broker-bid-form-input-book-it-now`"
          v-model="bookItNowAmount"
          class="ins"
          :floatinglabel="'Book It Now'"
          :tooltip="'Only certified companies can Book it Now'"
          :prepend-icon="'$vuetify.icons.bookIt'"
          :disabled="notPreferred"
        />

        <CRInput
          :id="`${id}-broker-bid-form-input-due-by`"
          v-model="biddingEndDate"
          :class="'ins'"
          :floatinglabel="'Due By'"
          type="date"
          :prepend-inner-event="false"
          :prepend-icon="'$vuetify.icons.event'"
        />
        <div>
          <label>Company List</label>
          <CRSelect
            :id="`${id}-broker-bid-form-select-allow-bidding-company-type`"
            v-model="allowedBiddingCompanyType"
            :items="companyList"
            solo
            flat
          />
        </div>

        <div v-if="allowedBiddingCompanyType === 'Selected'">
          <div class="company-selector">
            <label>Search for Companies</label>
            <v-autocomplete
              :id="`${id}-broker-bid-form-autocomplete-search-company`"
              v-model="companySelector"
              :items="affiliateList"
              :search-input.sync="search"
              flat
              solo
              clearable
              autofocus
              hide-no-data
              hide-selected
              item-text="name"
              item-value="companyId"
              placeholder="Start typing to Search"
              append-icon="keyboard_arrow_down"
              return-object
              @click:clear="(evt) => clearAffiliateSearch()"
              @change="search = null"
            />
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; width: 45%">
        <CRInput
          :id="`${id}-broker-bid-form-input-max-bid-amount`"
          v-model="maxBidAmount"
          class="ins"
          floatinglabel="Max"
          :prepend-icon="'$vuetify.icons.fullPayment'"
        />

        <CRInput
          :id="`${id}-broker-bid-form-input-time`"
          v-model="time"
          class="ins"
          floatinglabel="Time"
          type="time"
          :prepend-inner-event="false"
          :prepend-icon="'$vuetify.icons.accessTime'"
        />

        <div v-if="allowedBiddingCompanyType !== 'Selected'">
          <span>Market</span>
          <Multimarket
            :id="`${id}-broker-bid-form`"
            :set-filter="setFilter"
            :market-init="marketInit"
          />
        </div>

        <div class="bid-checkbox check-space top-box">
          <v-checkbox
            :id="`${id}-broker-bid-form-checkbox-require-coachrail`"
            v-model="requireCoachrail"
            label="Require CoachRail Usage and Tracking"
          />
        </div>
        <div class="bid-checkbox check-space">
          <v-checkbox
            :id="`${id}-broker-bid-form-checkbox-allow-partial-bid`"
            v-model="allowPartialBid"
            label="Allow Partial Bid"
          />
        </div>
      </div>
    </v-layout>
    <div v-if="selectedAffiliates.length > 0" class="selected-companies">
      <label>Selected Companies</label>
      <div>
        <div
          v-if="selectedAffiliates.length > 0"
          class="selected-companies-list"
        >
          <v-chip
            v-for="(affiliate, idx) in selectedAffiliates"
            :id="`${id}-broker-bid-form-chip-select-affiliate-${idx}`"
            :key="idx"
            label
            small
            close
            @input="(evt) => removeAffiliate(affiliate)"
          >
            {{ affiliate.name }}
          </v-chip>
        </div>
      </div>
    </div>

    <div class="provider-notes">
      <label>Add Notes for Providers</label>
      <v-textarea
        :id="`${id}-broker-bid-form-textarea-add-notes`"
        v-model="note"
        counter="3000"
        no-resize
        flat
        placeholder="Add Notes"
        solo
      />
    </div>
    <v-layout class="button-cont">
      <v-btn
        :id="`${id}-broker-bid-form-button-publish`"
        style="margin-right: -1px"
        class="btn-primaryaction"
        :disabled="checkIfAllowed"
        @click="submit"
      >
        Publish
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import Multimarket from './ModifiedMultimarkets.vue'
import affiliates from '@/services/affiliates'
import { capitalize } from '@/utils/string'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'

export default {
  components: {
    Multimarket,
  },
  props: {
    row: {
      type: Object,
      default() {
        return {}
      },
    },
    parent: {
      type: Object,
      default() {
        return {}
      },
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      companyList: ['All', 'Free', 'Paid', 'Selected'],
      allowedBiddingCompanyType: null,
      debounce: null,
      immediate: null,
      companySelector: null,
      selectedAffiliates: [],
      affiliateList: [],
      search: null,
      bookItNowAmount: '',
      biddingEndDate: null,
      date: null,
      note: '',
      markets: [],
      marketInit: [],
      maxBidAmount: '',
      allowPartialBid: false,
      time: '',
      notPreferred: false,
      tripId: null,
      checkIfAllowed: false,
      requireCoachrail: false,
    }
  },
  watch: {
    search() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      if (!this.immediate) {
        this.debounce = setTimeout(() => {
          this.immediate = true
          this.searchAffiliates()
        }, 500)
        return
      }

      this.searchAffiliates()
    },
    companySelector() {
      if (this.companySelector) {
        if (
          !this.selectedAffiliates.find(
            (co) => co.companyId === this.companySelector.companyId
          )
        ) {
          this.selectedAffiliates.push(this.companySelector)

          this.$nextTick(() => {
            this.companySelector = null
          })
        }
      }
    },
  },
  async mounted() {
    const currentUser = this.$store.getters['auth/currentUser']
    const data = await this.$store
      .dispatch('reservations/reservationByIdv2Legacy', {
        reservationId: this.row.reservationId,
        companyId: currentUser.companyId,
      })
      .then((dat) => dat.data)
    const tripResponse = await this.$store.dispatch(
      'trips/getTrip',
      data.tripId
    )
    const trip = tripResponse.data
    if (trip.id) {
      this.checkIfAllowed = true
    }
    this.note = trip.brokerNotes
    this.allowedBiddingCompanyType = capitalize(
      trip.allowedBiddingCompanyType || ''
    )
    this.bookItNowAmount = trip.bookItNowAmount
    this.tripId = trip.tripId
    this.biddingEndDate = trip.biddingEndDate
      ? trip.biddingEndDate.split('T')[0]
      : ''
    this.markets =
      trip.tripMarkets.map((mk) => {
        return mk.marketId
      }) || []
    this.marketInit = [...this.markets]
    this.allowPartialBid = trip.allowMultipleBids
    this.requireCoachrail = trip.usageTrackingFlag
    this.maxBidAmount = trip.maxBidAmount
    this.selectedAffiliates = trip.tripCompanies.map((af) => {
      return {
        name: af.companyName,
        companyId: af.companyId,
      }
    })
    this.time = trip.biddingEndDate
      ? trip.biddingEndDate.split('T')[1].split(':').slice(0, 2).join(':')
      : ''
  },
  methods: {
    clearAffiliateSearch() {
      this.search = null
      this.companySelect = null
    },
    async searchAffiliates() {
      this.immediate = false

      const filterObjects = []
      const affilSort = sort()
      const affilFilter = filter()

      if (this.search) {
        filterObjects.push({
          column: {
            _t_id: 'text_search_refer',
            prop: 'name',
            filterType: 'contains',
          },
          value: this.search,
        })
      }

      affilSort.add({
        prop: 'referralCount',
        direction: 'desc',
      })

      filterObjects.forEach((fil) => {
        const parent = affilFilter.createParent('and')
        affilFilter.add(parent, fil)
      })

      const filterParams = affilFilter.asQueryParams()
      const sortParams = affilSort.asQueryParams()

      this.affiliateList = await affiliates
        .getAffiliates({
          sorts: sortParams,
          filters: filterParams,
          pageSize: 20,
          page: 1,
        })
        .then(({ data }) => {
          return data.resultList
        })
        .catch((e) => {})

      this.immediate = true
    },
    removeAffiliate(selection) {
      this.selectedAffiliates = this.selectedAffiliates.filter(
        (affil) => affil.companyId !== selection.companyId
      )
    },
    async submit() {
      const payload = {
        allowedBiddingCompanyType:
          this.allowedBiddingCompanyType.toLowerCase() || 'all',
        biddingEndDate: `${this.biddingEndDate}T${this.time}:00.000+0000`,
        bookItNowAmount: this.bookItNowAmount,
        bidStart: null,
        allowMultipleBids: this.allowPartialBid,
        openForBid: true,
        usageTrackingFlag: this.requireCoachrail,
        brokerNotes: this.note,
        tripCompanies: this.selectedAffiliates.map((afil) => {
          return {
            tripId: this.tripId,
            companyId: afil.companyId,
            name: afil.name,
          }
        }),
        tripMarkets: this.markets.map((mk) => {
          return {
            marketId: mk,
            tripId: this.tripId,
          }
        }),
        maxBidAmount: this.maxBidAmount,
      }

      if (this.allowedBiddingCompanyType === 'Selected') {
        payload.tripMarkets = []
      } else {
        payload.tripCompanies = []
      }

      try {
        await this.$store.dispatch('trips/modifyBidTrip', {
          tripId: this.tripId,
          payload,
        })
        setTimeout(() => {
          this.$emit('refresh-query-request')
        }, 100)
      } catch (error) {
        throw new Error(error)
      }
    },
    setFilter(mk) {
      this.markets = mk
    },
  },
}
</script>

<style lang="scss" scoped>
.provider-notes {
  margin-top: 1%;
}

.form-cont {
  width: 100%;
  max-width: 1000px;

  .layout-panel {
    display: flex;
    justify-content: space-between;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }
}

.ico-lol {
  position: relative;
  top: 8px;
  left: 2px;
  font-size: 30px;
  color: $white;
}

.ico-cont {
  position: relative;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: blue;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.check-space {
  &.top-box {
    margin-top: 5px;
  }
  margin-top: 5px;
  margin-bottom: -35px;
}

.bid-checkbox {
  font-weight: bold;
  ::v-deep .v-label {
    font-size: 14px !important;
  }
  ::v-deep .accent--text {
    color: $primary !important;
  }
}

.ins::v-deep i,
.ins::v-deep .cr-icon {
  color: $white !important;
}

::v-deep.radius-selector {
  border: none;
}

::v-deep .v-chip {
  color: $white;
  background-color: $primary;
  border-radius: 5px;
}

.selected-companies-list {
  display: flex;
  flex-wrap: wrap;
  // width: 300px;
  padding: 5px;
  margin-top: 2%;
  margin-bottom: 2%;
  overflow-x: auto;
  background-color: $blue-light;
  border-radius: 5px;
}

.ins::v-deep div.v-input__prepend-outer {
  margin: 0 !important;
  background-color: $primary !important;
  border: 8px solid $primary;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ins::v-deep div.v-input__slot {
  border-radius: 0 5px 5px 0 !important;
}

.company-selector::v-deep input {
  text-align: left;
}
</style>
