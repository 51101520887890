import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { AvailabilityDataGetRequest, AvailabilityDataGetResponse, VehicleAvailabilityBlock } from '@/models/dto/Availability'

const httpService: HttpService = new HttpService()

export default {
  getVehicleTypeBlocks(params: { payload: AvailabilityDataGetRequest }): Promise<AxiosResponse<AvailabilityDataGetResponse>> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/getVehicleTypeBlocks`
    return httpService.post(url, payload)
  },
  getVehicleBlocks(params: { payload: AvailabilityDataGetRequest}): Promise<AxiosResponse<AvailabilityDataGetResponse>> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/getVehicleBlocks`
    return httpService.post(url, payload)
  },
  getCompanyBlocks(params: { payload: AvailabilityDataGetRequest }): Promise<AxiosResponse<AvailabilityDataGetResponse>> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/getCompanyBlocks`
    return httpService.post(url, payload)
  },
  createVehicleAvailabilityBlock(params: { payload: VehicleAvailabilityBlock }): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/createVehicleBlock`
    return httpService.post(url, payload)
  },
  editVehicleAvailabilityBlock(params: { payload: VehicleAvailabilityBlock }): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/updateVehicleBlock`
    return httpService.patch(url, payload)
  },
  deleteVehicleAvailabilityBlock(params: { payload: VehicleAvailabilityBlock }): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/deleteVehicleBlock`
    return httpService.delete(url, { data: payload })
  },
  createVehicleTypeAvailabilityBlock(params: { payload: VehicleAvailabilityBlock }): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/createVehicleTypeBlock`
    return httpService.post(url, payload)
  },
  editTypeVehicleAvailabilityBlock(params: { payload: VehicleAvailabilityBlock }): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/updateVehicleTypeBlock`
    return httpService.patch(url, payload)
  },
  deleteVehicleTypeAvailabilityBlock(params: { payload: VehicleAvailabilityBlock }): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/deleteVehicleTypeBlock`
    return httpService.delete(url, { data: payload })
  },
  availabilityBlockReasonType(): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/availabilityBlocks/getReasonTypes`

    return httpService.get(url)
  },
}
